import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthContext } from '../../../contexts/AuthContext';
import history from '../../../router/history';
import { ROUTES } from '../../../router/Router';
import { colors, MEDIA_QUERIES } from '../../../themes';

interface SettingsLayoutProps {
  children: ReactNode;
}

export function SettingsLayout(props: SettingsLayoutProps): JSX.Element {
  const { children } = props;

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { removeToken, token } = useAuthContext();

  useEffect(() => {
    if (!token) history.push(ROUTES.LOGIN);
  }, [token]);

  return (
    <SettingsContainer>
      <SettingsTabsContainer>
        <SettingsTab
          className={pathname === ROUTES.PROFILE ? 'active' : ''}
          onClick={() => history.push(ROUTES.PROFILE)}
        >
          {t('settings.menu.account')}
        </SettingsTab>
        <SettingsTab
          className={pathname === ROUTES.PROFILE_SUBSCRIPTION ? 'active' : ''}
          onClick={() => history.push(ROUTES.PROFILE_SUBSCRIPTION)}
        >
          {t('settings.menu.subscription')}
        </SettingsTab>
        <SettingsTab onClick={() => removeToken()}>
          {t('settings.menu.logout')}
        </SettingsTab>
      </SettingsTabsContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </SettingsContainer>
  );
}

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const SettingsTabsContainer = styled.div`
  max-width: 20%;
  min-width: 20%;
  flex-direction: column;
  border-right: 1px solid ${colors.greyLight};

  .active {
    font-weight: bold;
    border-right: 6px solid ${colors.primary};
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${colors.greyLight};
    border-right: none;
    height: 55px;

    .active {
      border-right: none;
      border-bottom: 3px solid ${colors.primary};
    }
  }
`;

const SettingsTab = styled.div`
  padding: 20px;
  transition: 0.5s;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;

  :hover {
    background-color: ${colors.primary}60;
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 5px 20px;
    width: 33%;
    text-align: center;
    height: 55px;
  }
`;

const ChildrenContainer = styled.div`
  max-width: 80%;
  min-width: 80%;

  @media ${MEDIA_QUERIES.MOBILE} {
    min-width: 100%;
    max-width: 100%;
  }
`;
