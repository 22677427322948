import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  display: boolean;
  color?: string;
}

export function ErrorMessage(props: Props): JSX.Element {
  const { children, display, color = '' } = props;

  return display ? (
    <StyledErrorText textColor={color}>{children}</StyledErrorText>
  ) : (
    <></>
  );
}

interface PropsDisplay {
  textColor: string;
}

const StyledErrorText = styled.p`
  color: ${(props: PropsDisplay) =>
    props.textColor !== '' ? props.textColor : 'red'};
`;
