import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  email: string;
  password: string;
}

export interface FormValues {
  email: string;
  password: string;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const { email = '', password = '' } = values;
  return {
    email,
    password,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    email: values.email,
    password: values.password,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};

  if (!Validator.isEmail(values.email))
    errors.email = 'Veuillez saisir un email valide';

  if (Validator.isEmpty(values.password))
    errors.password = 'Veuillez introduire votre mot de passe';

  return errors;
}
