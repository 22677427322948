import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface StoryTitleProps {
  children: ReactNode;
}

export function StoryTitle(props: StoryTitleProps): JSX.Element {
  const { children } = props;

  return <Title>{children}</Title>;
}

const Title = styled(Typography)`
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
`;
