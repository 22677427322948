import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormApi, FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  ErrorMessage,
  FormField,
  InputPassword,
  InputText,
  LabelInput,
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import ApiService from '../../services/network/apiService';
import { colors } from '../../themes';
import { MEDIA_QUERIES } from '../../themes/constants';
import {
  FormValues,
  getFormInitialValues,
  getFormStatePayload,
  validateFormValues,
} from './RegisterPage.formstate';

interface Props {}

export function RegisterPage(props: Props): JSX.Element {
  const {} = props;
  const { t } = useTranslation();

  const {
    setToken,
    refreshUser,
    currentUser,
    isAuthenticated,
  } = useAuthContext();

  if (isAuthenticated) refreshUser();

  useEffect(() => {
    if (isAuthenticated) history.push(ROUTES.HOME);
  }, [currentUser, isAuthenticated]);

  async function onSubmit(
    _: unknown,
    formApi: FormApi<FormValues, FormValues>
  ): Promise<{ [FORM_ERROR]: string | React.ReactNode } | undefined> {
    const formState = formApi.getState();
    const payload = getFormStatePayload(formState);

    try {
      const { email, password, firstName, lastName } = payload;
      const token = await ApiService.register(
        email,
        password,
        firstName,
        lastName
      );
      setToken(token);
      refreshUser();
      history.push(ROUTES.SUBSCRIPTION);
    } catch (e) {
      return { [FORM_ERROR]: 'Login Failed' };
    }
  }

  return (
    <PageBackground>
      <GoBackContainer onClick={() => history.push(ROUTES.HOME)}>
        <ArrowLeftOutlined />
        <GoBackText>{t('component.button.goBack')}</GoBackText>
      </GoBackContainer>
      <Main>
        <ContentContainer>
          <TitleContainer>
            <TypographyTitleStyled>{t('register.title')}</TypographyTitleStyled>
          </TitleContainer>
          <Form
            initialValues={getFormInitialValues()}
            validate={validateFormValues}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              hasValidationErrors,
              hasSubmitErrors,
              dirtySinceLastSubmit,
              submitting,
            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any) => {
              return (
                <FormContainer onSubmit={handleSubmit}>
                  <FormField
                    name='firstName'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('register.firstName.firstNameTitle')}
                          </LabelInput>
                          <InputText
                            placeholder={t(
                              'register.firstName.firstNamePlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <FormField
                    name='lastName'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('register.lastName.lastNameTitle')}
                          </LabelInput>
                          <InputText
                            placeholder={t(
                              'register.lastName.lastNamePlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <FormField
                    name='email'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('register.email.emailTitle')}
                          </LabelInput>
                          <InputText
                            placeholder={t('register.email.emailPlaceholder')}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>

                  <FormField
                    name='password'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('register.password.passwordTitle')}
                          </LabelInput>
                          <InputPassword
                            placeholder={t(
                              'register.password.passwordPlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <FormField
                    name='confirmPassword'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('register.confirmPassword.confirmPasswordTitle')}
                          </LabelInput>
                          <InputPassword
                            placeholder={t(
                              'register.confirmPassword.confirmPasswordPlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <ButtonStyled
                    htmlType='submit'
                    type='primary'
                    disabled={
                      hasValidationErrors ||
                      (hasSubmitErrors && !dirtySinceLastSubmit) ||
                      submitting
                    }
                  >
                    {t('register.buttonConnexion')}
                  </ButtonStyled>
                  <ErrorMessage display={hasSubmitErrors}>
                    {t('register.errors.wrongCredentials')}
                  </ErrorMessage>
                  <SignupContainer>
                    <TypographyTextNotCliquable>
                      {t('register.signup.text')}
                    </TypographyTextNotCliquable>
                    <Link to={ROUTES.LOGIN} style={{ marginLeft: '5px' }}>
                      <TypographyTextLink>
                        {t('register.signup.link')}
                      </TypographyTextLink>
                    </Link>
                  </SignupContainer>

                  <SignupContainer>
                    <TypographyTextNotCliquable>
                      {t('register.tos.text1')}
                    </TypographyTextNotCliquable>
                    <Link
                      to={ROUTES.LEGAL_NOTICE}
                      style={{ marginLeft: '5px' }}
                    >
                      <TypographyTextLink>
                        {t('register.tos.link1')}
                      </TypographyTextLink>
                    </Link>
                    <TypographyTextNotCliquable>
                      {t('register.tos.text2')}
                    </TypographyTextNotCliquable>
                    <Link
                      to={ROUTES.PRIVACY_POLICY}
                      style={{ marginLeft: '5px' }}
                    >
                      <TypographyTextLink>
                        {t('register.tos.link2')}
                      </TypographyTextLink>
                    </Link>
                  </SignupContainer>
                </FormContainer>
              );
            }}
          </Form>
        </ContentContainer>
      </Main>
    </PageBackground>
  );
}

const PageBackground = styled.div`
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
  padding-top: 20px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const GoBackContainer = styled.div`
  margin-left: 20px;
  margin-bottom: -20px;
  display: inline-flex;
  align-items: center;
  background-color: ${colors.buttonBackground};
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
`;

const GoBackText = styled.div`
  margin: 0 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 0 35px 25px 35px;

  @media ${MEDIA_QUERIES.MOBILE} {
    margin: 10px 10px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 40vw;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin: 35px 0;
  background-color: ${colors.buttonBackground};
  :hover {
    background-color: ${colors.darkPink};
  }
`;

const TypographyTitleStyled = styled(Typography)`
  font-size: 2.5em;
  font-weight: bold;
`;

const TypographyTextNotCliquable = styled(Typography)`
  color: ${colors.greyText};
`;

const TypographyTextLink = styled(Typography)`
  color: ${colors.buttonBackground};
  text-decoration: underline;
`;
