import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import NoMatch from './NoMatch';

function ReportingNoMatch(): JSX.Element {
  useEffect(() => {
    // Logs an event to Sentry
  });

  return <Route component={NoMatch} />;
}

interface Props {
  children: React.ReactNode;
}

// A react-router Switch which handle routes not found
function SwitchNotFoundHandler({ children, ...props }: Props): JSX.Element {
  return (
    <Switch {...props}>
      {children}
      <ReportingNoMatch />
    </Switch>
  );
}

export { SwitchNotFoundHandler };
