/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from 'i18next';
import {
  LinearStoryChoiceItem,
  LinearStoryResolveType,
  StoryCreationConfig,
} from '../types/LinearStory';
import { LinearStoryCreationPageStep } from './../types/LinearStory';
import { convertEnumToArray } from './utils';

function removeRT(
  resolveTypes: string[],
  toRemove: LinearStoryResolveType[]
): string[] {
  return resolveTypes.filter(
    (resolveType: string) =>
      !toRemove.includes(resolveType as LinearStoryResolveType)
  );
}

function isFieldValid(field: string): boolean {
  return field !== '__EMPTY';
}

export function generateBasei18nStringFromStoryConfig(
  config: StoryCreationConfig,
  resolveType: LinearStoryResolveType
): string {
  let RESOLVE_TYPES = convertEnumToArray(LinearStoryResolveType);
  let i18nString = 'stories';

  if (resolveType === LinearStoryResolveType.STORIES) return i18nString;

  if (isFieldValid(config.gender))
    i18nString = `${i18nString}.${config.gender}`;
  else return i18nString.toLowerCase();
  RESOLVE_TYPES = removeRT(RESOLVE_TYPES, [LinearStoryResolveType.GENDER]); // remove GENDER

  if (isFieldValid(config.character) && RESOLVE_TYPES.includes(resolveType))
    i18nString = `${i18nString}.${config.character}`;
  else return i18nString.toLowerCase();
  RESOLVE_TYPES = removeRT(RESOLVE_TYPES, [LinearStoryResolveType.CHARACTER]); // remove CHARACTER

  if (isFieldValid(config.place) && RESOLVE_TYPES.includes(resolveType))
    i18nString = `${i18nString}.${config.place}`;
  else return i18nString.toLowerCase();
  RESOLVE_TYPES = removeRT(RESOLVE_TYPES, [
    LinearStoryResolveType.PLACE,
    LinearStoryResolveType.BASE,
  ]); // remove PLACE and BASE

  if (isFieldValid(config.item) && resolveType === LinearStoryResolveType.ITEMS)
    i18nString = `${i18nString}.items.${config.item}`;
  if (
    isFieldValid(config.emotion) &&
    resolveType === LinearStoryResolveType.EMOTIONS
  )
    i18nString = `${i18nString}.emotions.${config.emotion}`;

  return i18nString.toLowerCase();
}

export function formati18nElementToLinearStoryChoiceItem(
  element: any,
  value: string
): LinearStoryChoiceItem {
  return {
    value,
    title: element.__title,
    asset: element.__asset,
    premium: !!element.__premium,
  };
}

export function generateChoiceItemFromi18nBlock(
  block: any[]
): LinearStoryChoiceItem[] {
  if (typeof block === 'string') return [];
  return Object.keys(block)
    .filter((i18nKey: string) => !i18nKey.startsWith('__'))
    .map((i18nKey: string) => {
      return formati18nElementToLinearStoryChoiceItem(
        block[i18nKey as any],
        i18nKey
      );
    });
}

// TODO: Refactor this function to take LinearStoryResolveType for what you want to get, not the parent step
export function getLinearChoiceItems(
  t: TFunction,
  config: StoryCreationConfig,
  resolveType: LinearStoryResolveType
): LinearStoryChoiceItem[] {
  // Limit ITEMS and EMOTIONS resolving to prevent resolving to items instead of choices
  const resolveTypeForBaseString = [
    LinearStoryResolveType.ITEMS,
    LinearStoryResolveType.EMOTIONS,
  ].includes(resolveType)
    ? LinearStoryResolveType.BASE
    : resolveType;

  // generate i18n string for use case
  let i18nString = generateBasei18nStringFromStoryConfig(
    config,
    resolveTypeForBaseString
  );

  // If ITEMS or EMOTIONS, add path
  if (resolveType === LinearStoryResolveType.ITEMS)
    i18nString = `${i18nString}.items`;
  if (resolveType === LinearStoryResolveType.EMOTIONS)
    i18nString = `${i18nString}.emotions`;

  return generateChoiceItemFromi18nBlock(t(i18nString));
}

export function generateTextFromStoryConfig(
  t: TFunction,
  config: StoryCreationConfig
): string {
  const baseI18nString = generateBasei18nStringFromStoryConfig(
    config,
    LinearStoryResolveType.BASE
  );
  const emotionI18nString = generateBasei18nStringFromStoryConfig(
    config,
    LinearStoryResolveType.EMOTIONS
  );
  const itemI18nString = generateBasei18nStringFromStoryConfig(
    config,
    LinearStoryResolveType.ITEMS
  );

  const emotion = t(`${emotionI18nString}.__text`, {
    name: config.name,
    color: t(`storyColors.${config.color}.__title`).toLowerCase(),
  });

  const item = t(`${itemI18nString}.__text`, {
    name: config.name,
    color: t(`storyColors.${config.color}.__title`).toLowerCase(),
  });

  return t(`${baseI18nString}.__text`, {
    name: config.name,
    color: t(`storyColors.${config.color}.__title`).toLowerCase(),
    emotion,
    item,
  });
}

export function getBackgroundAssetForStoryConfig(
  t: TFunction,
  storyConfig: StoryCreationConfig,
  mobile: boolean
): string {
  if (
    !isFieldValid(storyConfig.gender) ||
    !isFieldValid(storyConfig.character) ||
    !isFieldValid(storyConfig.place)
  )
    return '';
  const placeUrl = generateBasei18nStringFromStoryConfig(
    storyConfig,
    LinearStoryResolveType.PLACE
  );
  const placeBlock: any = t(placeUrl);
  const webAsset = placeBlock?.__backgroundAsset
    ? placeBlock.__backgroundAsset
    : '';
  const mobileAsset = placeBlock?.__backgroundAssetMobile
    ? placeBlock.__backgroundAssetMobile
    : '';
  return mobile ? mobileAsset : webAsset;
}

export function getStoryTitleForStoryConfig(
  t: TFunction,
  storyConfig: StoryCreationConfig
): string {
  if (
    !isFieldValid(storyConfig.gender) ||
    !isFieldValid(storyConfig.character) ||
    !isFieldValid(storyConfig.place)
  )
    return '';
  const placeUrl = generateBasei18nStringFromStoryConfig(
    storyConfig,
    LinearStoryResolveType.PLACE
  );
  const placeBlock: any = t(placeUrl);
  return placeBlock?.__storyTitle ? placeBlock.__storyTitle : '';
}

export function generateEmptyStoryConfig(): StoryCreationConfig {
  return {
    name: '',
    gender: '__EMPTY',
    color: '__EMPTY',
    character: '__EMPTY',
    place: '__EMPTY',
    emotion: '__EMPTY',
    item: '__EMPTY',
  };
}

export function validateStoryConfig(config: StoryCreationConfig): boolean {
  if (config.name === '') return false;
  if (config.gender === '__EMPTY') return false;
  if (config.character === '__EMPTY') return false;
  if (config.color === '__EMPTY') return false;
  if (config.character === '__EMPTY') return false;
  if (config.place === '__EMPTY') return false;
  if (config.emotion === '__EMPTY') return false;
  if (config.item === '__EMPTY') return false;
  return true;
}

export function getColorsChoiceItems(t: TFunction): LinearStoryChoiceItem[] {
  return generateChoiceItemFromi18nBlock(t('storyColors')).map(
    (color: LinearStoryChoiceItem) => {
      return {
        ...color,
        value: color.value.toLowerCase(),
      };
    }
  );
}

export function getCurrentLinearStoryStep(
  config: StoryCreationConfig
): LinearStoryCreationPageStep {
  if (config.name === '__EMPTY') return LinearStoryCreationPageStep.NAME;
  if (config.color === '__EMPTY') return LinearStoryCreationPageStep.COLOR;
  if (config.gender === '__EMPTY') return LinearStoryCreationPageStep.GENDER;
  if (config.character === '__EMPTY')
    return LinearStoryCreationPageStep.CHARACTER;
  if (config.place === '__EMPTY') return LinearStoryCreationPageStep.PLACE;
  if (config.emotion === '__EMPTY') return LinearStoryCreationPageStep.EMOTION;
  if (config.item === '__EMPTY') return LinearStoryCreationPageStep.ITEM;
  return LinearStoryCreationPageStep.FINISHED;
}
