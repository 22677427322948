import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NoMatch = (): JSX.Element => {
  const { t } = useTranslation();

  return <NoMatchText>{t('404.title')}</NoMatchText>;
};

const NoMatchText = styled.div`
  text-align: center;
`;

export default NoMatch;
