import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

interface Props extends React.ComponentProps<typeof Input> {}

export const InputText: React.FC<Props> = (props) => {
  return <InputStyled {...props} />;
};

const InputStyled = styled(Input)`
  min-height: 45px;
  border: 1px solid ${colors.greyLight};
  border-radius: 2px;
  background-color: white;
  padding: 0 15px;
`;
