import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface StoryTextProps {
  children: ReactNode;
}

export function StoryText(props: StoryTextProps): JSX.Element {
  const { children } = props;

  return <Text>{children}</Text>;
}

const Text = styled(Typography)`
  text-align: justify;
`;
