import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export function ButtonSecondary(props: Props): JSX.Element {
  const { children, onClick, className } = props;
  return (
    <ButtonStyled className={className} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
}

const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.buttonBackground};
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 1.4em;
  font-weight: bold;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
`;
