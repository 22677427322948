import { Image, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuthContext } from '../../../contexts/AuthContext';
import history from '../../../router/history';
import { ROUTES } from '../../../router/Router';
import { colors } from '../../../themes';

export function Footer(): JSX.Element {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuthContext();

  const localLinks = [
    {
      label: t('footer.localLinks.link1'),
      link: ROUTES.SUBSCRIPTION,
    },
    {
      label: isAuthenticated
        ? t('footer.localLinks.link2-auth')
        : t('footer.localLinks.link2'),
      link: isAuthenticated ? ROUTES.PROFILE : ROUTES.LOGIN,
    },
    {
      label: t('footer.localLinks.link3'),
      link: ROUTES.LINEAR_STORY_CREATION,
    },
    {
      label: t('footer.localLinks.link4'),
      link: ROUTES.INTERACTIVE_STORY_CREATION,
    },
  ];
  const legalLinks = [
    {
      label: t('footer.legalLinks.link1'),
      link: ROUTES.LEGAL_NOTICE,
    },
    {
      label: t('footer.legalLinks.link2'),
      link: ROUTES.PRIVACY_POLICY,
    },
    {
      label: t('footer.legalLinks.link3'),
      link: '',
    },
  ];
  const contactLinks = [
    {
      label: t('footer.contactLinks.link1'),
      link: ROUTES.CONTACT,
    },
    {
      label: t('footer.contactLinks.link2'),
      link: ROUTES.FAQ,
    },
  ];

  interface redirectLink {
    label: string;
    link: string;
  }

  function renderBloc(linkList: redirectLink[]) {
    return (
      <BlocContainer>
        {linkList.map((link: redirectLink) => {
          return (
            <Link key={link.label} onClick={() => history.push(link.link)}>
              {link.label}
            </Link>
          );
        })}
      </BlocContainer>
    );
  }

  return (
    <Main>
      <Title>{t('footer.title')}</Title>
      <ImgContainer>
        <a
          href='https://www.facebook.com/KIDcreastory'
          rel='noreferrer'
          target='_blank'
        >
          <ImgStyled
            width={40}
            preview={false}
            src='/assets/socialMedia/facebookLogo.png'
            onClick={() => {
              return;
            }}
          />
        </a>
        <a
          href='https://www.instagram.com/kidcreastory/'
          rel='noreferrer'
          target='_blank'
        >
          <ImgStyled
            width={40}
            preview={false}
            src='/assets/socialMedia/instagramLogo.png'
            onClick={() => {
              return;
            }}
          />
        </a>
      </ImgContainer>
      <Company>{t('footer.company')}</Company>
      <RedirectContainer>
        {renderBloc(localLinks)}
        {renderBloc(legalLinks)}
        {renderBloc(contactLinks)}
      </RedirectContainer>
      <Subtitle>{t('footer.subtitle')}</Subtitle>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  background-color: white;
  border-top: 3px black solid;
  padding: 15px;
  margin-top: auto;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RedirectContainer = styled.div`
  display: flex;
  flex: 1;
`;

const BlocContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ImgStyled = styled(Image)`
  padding: 5px;
  :hover {
    cursor: pointer;
  }
`;

const Title = styled(Typography)`
  align-self: center;
  font-size: 1.3em;
`;

const Company = styled(Typography)``;

const Link = styled(Typography.Link)`
  &.ant-typography {
    color: black;
    font-size: 0.9em;
    :hover {
      color: ${colors.greyText};
    }
  }
`;

const Subtitle = styled(Typography)`
  align-self: center;
`;
