import React, { useState } from 'react';
import ApiService from '../services/network/apiService';
import { ApiChoice, ApiStory } from '../types';

interface State {
  storyPath: ApiChoice[];
  storyName: string;
}

interface Context extends State {
  makeChoice: (choiceId: string) => void;
  resetStory: () => void;
  startStory: (story: ApiStory) => void;
  storyPath: ApiChoice[];
  storyName: string;
}

const defaultState: State = {
  storyPath: [],
  storyName: '',
};

const InteractiveStoryContext = React.createContext<Context>({
  ...defaultState,
  makeChoice: () => {
    throw new Error('InteractiveStoryContext.makeChoice has not been set');
  },
  resetStory: () => {
    throw new Error('InteractiveStoryContext.resetStory has not been set');
  },
  startStory: () => {
    throw new Error('InteractiveStoryContext.startStory has not been set');
  },
});

function useInteractiveStoryProvider() {
  const [storyPath, setStoryPath] = useState<ApiChoice[]>([]);
  const [storyName, setStoryName] = useState('');

  async function makeChoice(choiceId: string) {
    const apiChoice: ApiChoice = await ApiService.getChoice(choiceId);
    setStoryPath(storyPath.concat(apiChoice));
  }

  async function startStory(story: ApiStory) {
    const apiChoice: ApiChoice = await ApiService.getChoice(`${story.id}001`);
    setStoryName(story.name);
    setStoryPath([apiChoice]);
  }

  function resetStory() {
    setStoryPath([]);
  }

  return {
    storyPath,
    storyName,
    makeChoice,
    startStory,
    resetStory,
  };
}

interface Props {
  children: React.ReactNode;
}

export const InteractiveStoryProvider = ({ children }: Props): JSX.Element => {
  const context: Context = useInteractiveStoryProvider();
  return (
    <InteractiveStoryContext.Provider value={context}>
      {children}
    </InteractiveStoryContext.Provider>
  );
};

export const useInteractiveStoryContext = (): Context =>
  React.useContext(InteractiveStoryContext);
