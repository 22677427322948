import { Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useInteractiveStoryContext } from '../../contexts/InteractiveStoryContext';
import { useMountEffect } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import apiService from '../../services/network/apiService';
import { colors } from '../../themes';
import { ApiStory } from '../../types';
import { Layout } from '../Layout';
import { StoryInteractiveSelectionChoicePage } from './StoryInteractiveSelectionChoicePage';

export function StoryInteractiveSelectionPage(): JSX.Element {
  const [listOfInteractiveStories, setListOfInteractiveStories] = useState(
    [] as ApiStory[]
  );
  const { startStory } = useInteractiveStoryContext();
  const { t } = useTranslation();

  useMountEffect(() => {
    async function getAllStories() {
      const allStories: ApiStory[] = await apiService.getAllStories();
      if (allStories) setListOfInteractiveStories(allStories);
    }
    getAllStories();
  });

  return (
    <Layout>
      <Main>
        <Title>{t('InteractiveSelectionStory.title')}</Title>
        <StoryInteractiveSelectionChoicePage
          title=''
          text=''
          stories={listOfInteractiveStories}
          currentlySelected=''
          onChoice={(value: ApiStory) => startStory(value)}
          next={() => history.push(ROUTES.INTERACTIVE_STORY)}
        />
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const Title = styled(Typography)`
  font-size: 2.5em;
  font-weight: bold;
  align-self: center;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${colors.textPrimary};
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;
