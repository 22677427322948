import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormApi, FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  Button,
  ErrorMessage,
  FormField,
  InputPassword,
  LabelInput,
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMountEffect, useUrlSearchParams } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import {
  default as apiService,
  default as ApiService,
} from '../../services/network/apiService';
import { colors } from '../../themes';
import { MEDIA_QUERIES } from '../../themes/constants';
import {
  FormValues,
  getFormInitialValues,
  getFormStatePayload,
  validateFormValues,
} from './ResetPasswordPage.formstate';

// TODO: get token
// TODO: verify token
// TODO: manage form
// TODO: make request
// TODO: display errors
// TODO: login user on success
// TODO: redirect

interface ResetPasswordPageProps {}
export function ResetPasswordPage(props: ResetPasswordPageProps): JSX.Element {
  const {} = props;

  const { t } = useTranslation();

  const { resetPasswordToken } = useUrlSearchParams();

  function endProcess() {
    toast(t('resetPassword.noToken'));
    setTimeout(() => history.push(ROUTES.HOME), 2000);
  }

  useMountEffect(() => {
    if (!resetPasswordToken) {
      endProcess();
      return;
    }

    async function verifyToken() {
      try {
        await apiService.resetPasswordVerifyToken(resetPasswordToken || '');
      } catch (e) {
        endProcess();
        return;
      }
    }

    verifyToken();
  });

  const {
    setToken,
    refreshUser,
    currentUser,
    isAuthenticated,
  } = useAuthContext();

  if (isAuthenticated) refreshUser();

  useEffect(() => {
    if (isAuthenticated) history.push(ROUTES.HOME);
  }, [currentUser, isAuthenticated]);

  async function onSubmit(
    _: unknown,
    formApi: FormApi<FormValues, FormValues>
  ): Promise<{ [FORM_ERROR]: string | React.ReactNode } | undefined> {
    const formState = formApi.getState();
    const payload = getFormStatePayload(formState);

    try {
      const { password } = payload;
      const token = await ApiService.resetPassword(
        resetPasswordToken || '',
        password
      );
      setToken(token);
      refreshUser();
      toast(t('resetPassword.toast'));
      history.push(ROUTES.HOME);
    } catch (e) {
      return { [FORM_ERROR]: 'Login Failed' };
    }
  }

  return (
    <PageBackground>
      <GoBackContainer onClick={() => history.goBack()}>
        <ArrowLeftOutlined />
        <GoBackText>{t('component.button.goBack')}</GoBackText>
      </GoBackContainer>
      <Main>
        <ContentContainer>
          <TitleContainer>
            <TypographyTitleStyled>
              {t('resetPassword.title')}
            </TypographyTitleStyled>
          </TitleContainer>
          <Form
            initialValues={getFormInitialValues()}
            validate={validateFormValues}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              hasValidationErrors,
              hasSubmitErrors,
              dirtySinceLastSubmit,
              submitting,
            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any) => {
              return (
                <FormContainer onSubmit={handleSubmit}>
                  <FormField
                    name='password'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t('resetPassword.password.passwordTitle')}
                          </LabelInput>
                          <InputPassword
                            placeholder={t(
                              'resetPassword.password.passwordPlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <FormField
                    name='confirmPassword'
                    matchHasError={({ meta }) =>
                      meta.touched === true && meta.error !== undefined
                    }
                  >
                    {({ input }) => {
                      return (
                        <InputContainer>
                          <LabelInput>
                            {t(
                              'resetPassword.confirmPassword.confirmPasswordTitle'
                            )}
                          </LabelInput>
                          <InputPassword
                            placeholder={t(
                              'resetPassword.confirmPassword.confirmPasswordPlaceholder'
                            )}
                            {...input}
                          />
                        </InputContainer>
                      );
                    }}
                  </FormField>
                  <ButtonStyled
                    htmlType='submit'
                    type='primary'
                    disabled={
                      hasValidationErrors ||
                      (hasSubmitErrors && !dirtySinceLastSubmit) ||
                      submitting
                    }
                  >
                    {t('resetPassword.button')}
                  </ButtonStyled>
                  <ErrorMessage display={hasSubmitErrors}>
                    {t('resetPassword.error')}
                  </ErrorMessage>
                </FormContainer>
              );
            }}
          </Form>
        </ContentContainer>
      </Main>
    </PageBackground>
  );
}

const PageBackground = styled.div`
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
  padding-top: 20px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const GoBackContainer = styled.div`
  margin-left: 20px;
  margin-bottom: -20px;
  display: inline-flex;
  align-items: center;
  background-color: ${colors.buttonBackground};
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
`;

const GoBackText = styled.div`
  margin: 0 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 0 35px 25px 35px;

  @media ${MEDIA_QUERIES.MOBILE} {
    margin: 10px 10px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 40vw;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin: 35px 0;
  background-color: ${colors.buttonBackground};
  :hover {
    background-color: ${colors.darkPink};
  }
`;

const TypographyTitleStyled = styled(Typography)`
  font-size: 2.5em;
  font-weight: bold;
`;
