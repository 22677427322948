import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import EN from './local/en.json';
import FR from './local/fr.json';
import ID from './local/id.json';
import ES from './local/es.json';
import ZH_CN from './local/zh_cn.json';

export enum ILanguage {
  fr = 'fr',
  en = 'en',
  id = 'id',
  es = 'es',
  zh_cn = 'zh_cn',
}

export const AvailableLanguageAssets = {
  [ILanguage.fr]: {
    langCode: ILanguage.fr,
    asset:
      'https://www.privacylaws.com/media/2903/france-flag-square-small.jpg',
  },
  [ILanguage.en]: {
    langCode: ILanguage.en,
    asset: 'https://vectorflags.s3.amazonaws.com/flags/uk-square-01.png',
  },
  [ILanguage.id]: {
    langCode: ILanguage.id,
    asset: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/2560px-Flag_of_Indonesia.svg.png',
  },
  [ILanguage.es]: {
    langCode: ILanguage.es,
    asset: 'https://www.kindpng.com/picc/m/20-208191_spain-flag-graphic-spanish-symbol-icon-banner-spain.png',
  },
  [ILanguage.zh_cn]: {
    langCode: ILanguage.zh_cn,
    asset: 'https://cdn.countryflags.com/thumbs/china/flag-round-250.png',
  },
};

const resources = {
  [ILanguage.fr]: {
    translation: FR,
  },
  [ILanguage.en]: {
    translation: EN,
  },
  [ILanguage.id]: {
    translation: ID,
  },
  [ILanguage.es]: {
    translation: ES,
  },
  [ILanguage.zh_cn]: {
    translation: ZH_CN,
  },
};

export const DEFAULT_LANGUAGE = ILanguage.fr;

i18n
  .use(LanguageDetector) // detect browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: [ILanguage.fr, ILanguage.en, ILanguage.id, ILanguage.es],
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { i18n };
