import { Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonPrimary } from '../../components';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import {
  formatHTMLText,
  formatMultilineTextToHtml,
} from '../../services/utils';
import { IMAGE_FALLBACK } from '../../themes';
import { MEDIA_QUERIES } from '../../themes/constants';
import { Layout } from '../Layout';

interface Props {}

export function HomePage(props: Props): JSX.Element {
  const {} = props;
  const { t } = useTranslation();
  const [imageWidth, setImageWidth] = useState(window.innerWidth / 2);

  useEffect(() => {
    function onWindowResize() {
      setImageWidth(window.innerWidth / 2);
    }
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  function htmlFormat(text: string) {
    return ReactHtmlParser(formatMultilineTextToHtml(text));
  }

  return (
    <Layout>
      <Main>
        <Section1>
          <InfoPlacement>
            <InfoContainer>
              <ButtonContainer>
                <ButtonPrimary
                  onClick={() => history.push(ROUTES.LINEAR_STORY_CREATION)}
                >
                  {htmlFormat(t('home.buttonSection1'))}
                </ButtonPrimary>
              </ButtonContainer>
              <InfoText>{htmlFormat(t('home.textSection1'))}</InfoText>
            </InfoContainer>
          </InfoPlacement>
        </Section1>
        <Section2>
          <ImageStyled
            width={`${imageWidth}px`}
            preview={false}
            src='/assets/homePageAssets/home-page-section2.svg'
            fallback={IMAGE_FALLBACK}
          />
          <TextContainer>
            <Title>{formatHTMLText(t('home.titleSection2'))}</Title>
            <Text>{htmlFormat(t('home.textSection2'))}</Text>
            <ButtonContainer>
              <ButtonPrimary
                onClick={() => history.push(ROUTES.INTERACTIVE_STORY_CREATION)}
              >
                {t('home.buttonSection2')}
              </ButtonPrimary>
            </ButtonContainer>
          </TextContainer>
        </Section2>
        <Section3>
          <TextContainer>
            <Title>{t('home.titleSection3')}</Title>
            <Text>{htmlFormat(t('home.textSection3'))}</Text>
            <ButtonContainer>
              <ButtonPrimary
                onClick={() => history.push(ROUTES.LINEAR_STORY_CREATION)}
              >
                {t('home.buttonSection3')}
              </ButtonPrimary>
            </ButtonContainer>
          </TextContainer>
          <ImageStyled
            width={`${imageWidth}px`}
            src='/assets/homePageAssets/home-page-section3.svg'
            preview={false}
            fallback={IMAGE_FALLBACK}
          />
        </Section3>
        <Section4>
          <ImageStyled
            width={`${imageWidth}px`}
            src='/assets/homePageAssets/home-page-section4.svg'
            preview={false}
            fallback={IMAGE_FALLBACK}
          />
          <TextContainer>
            <Title>{t('home.title1Section4')}</Title>
            <Text>
              <p>{htmlFormat(t('home.text1Section4'))}</p>
            </Text>
            <Title>{t('home.title2Section4')}</Title>
            <Text>
              <p>{htmlFormat(t('home.text2Section4'))}</p>
            </Text>
            <ButtonContainer>
              <ButtonPrimary
                onClick={() => {
                  history.push(ROUTES.SUBSCRIPTION);
                  return;
                }}
              >
                {t('home.buttonSection4')}
              </ButtonPrimary>
            </ButtonContainer>
          </TextContainer>
        </Section4>
      </Main>
    </Layout>
  );
}

interface ImageProps {
  height?: string;
  width?: string;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section1 = styled.div`
  display: flex;
  min-height: 100vh;
  background-image: url('/assets/homePageAssets/HOME_PAGE_1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Section2 = styled.div`
  display: flex;
  flex: 1;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Section3 = styled.div`
  display: flex;
  flex: 1;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Section4 = styled.div`
  display: flex;
  flex: 1;
  background-image: url('/assets/background-color.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const InfoPlacement = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: 20%;

  @media ${MEDIA_QUERIES.MOBILE} {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 20px;
`;

const ImageStyled = styled(Image)`
  height: ${(props: ImageProps) => (props.height ? props.height : 'auto')};
  width: ${(props: ImageProps) => (props.width ? props.width : 'auto')};
`;

const ButtonContainer = styled.div`
  margin: 20px 0 10px 0;
  text-align: center;
`;

const InfoText = styled(Typography)`
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  margin: 0 15px 15px 15px;
`;

const Title = styled(Typography)`
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
`;

const Text = styled(Typography)`
  font-size: 1.3em;
`;
