import { Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStoryCreationContext } from '../../contexts/StoryCreationContext';
import { useMountEffect } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import {
  getColorsChoiceItems,
  getCurrentLinearStoryStep,
  getLinearChoiceItems,
} from '../../services/linearStory';
import { colors } from '../../themes';
import {
  LinearStoryChoiceItem,
  LinearStoryCreationPageStep,
  LinearStoryResolveType,
} from '../../types';
import { Layout } from '../Layout';
import { StoryCreationChoicePage } from './StoryCreationChoicePage';
import { StoryCreationNamePage } from './StoryCreationName';

export function StoryCreationPage(): JSX.Element {
  const { t } = useTranslation();
  const { storyConfig, updateStoryConfig } = useStoryCreationContext();

  const [index, setIndex] = useState(0);

  useMountEffect(() => {
    const currentStep = getCurrentLinearStoryStep(storyConfig);
    if (currentStep === LinearStoryCreationPageStep.EMOTION) setIndex(5);
  });

  function _nextStep() {
    setIndex(index + 1);
  }

  function _prevStep() {
    setIndex(index - 1 >= 0 ? index - 1 : index);
  }

  function renderSlide(
    title: string,
    text: string,
    choices: LinearStoryChoiceItem[],
    currentlySelected: string,
    selectChoice: (value: string) => void
  ) {
    return (
      <StoryCreationChoicePage
        title={title}
        text={text}
        choices={choices}
        currentlySelected={currentlySelected}
        onChoice={selectChoice}
        prev={_prevStep}
        next={_nextStep}
      />
    );
  }

  return (
    <Layout>
      <Main>
        <Background>
          <Title>{t('linearCreationStory.title')}</Title>
          <SwiperStyled>
            {index === 0 && (
              <StoryCreationNamePage
                title={t('linearCreationStory.name.title')}
                text={t('linearCreationStory.name.text')}
                defaultName={storyConfig.name}
                onChoice={(value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    name: value,
                  });
                }}
                prev={_prevStep}
                next={_nextStep}
              />
            )}
            {index === 1 &&
              renderSlide(
                t('linearCreationStory.color.title'),
                t('linearCreationStory.color.text'),
                getColorsChoiceItems(t),
                storyConfig.color,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    color: value,
                  });
                }
              )}
            {index === 2 &&
              renderSlide(
                t('linearCreationStory.gender.title'),
                t('linearCreationStory.gender.text'),
                getLinearChoiceItems(
                  t,
                  storyConfig,
                  LinearStoryResolveType.STORIES
                ),
                storyConfig.gender,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    gender: value,
                  });
                }
              )}
            {index === 3 &&
              renderSlide(
                t('linearCreationStory.character.title'),
                t('linearCreationStory.character.text'),
                getLinearChoiceItems(
                  t,
                  storyConfig,
                  LinearStoryResolveType.GENDER
                ),
                storyConfig.character,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    character: value,
                  });
                }
              )}
            {index === 4 &&
              renderSlide(
                t('linearCreationStory.place.title'),
                t('linearCreationStory.place.text'),
                getLinearChoiceItems(
                  t,
                  storyConfig,
                  LinearStoryResolveType.CHARACTER
                ),
                storyConfig.place,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    place: value,
                  });
                }
              )}
            {index === 5 &&
              renderSlide(
                t('linearCreationStory.emotion.title'),
                t('linearCreationStory.emotion.text'),
                getLinearChoiceItems(
                  t,
                  storyConfig,
                  LinearStoryResolveType.EMOTIONS
                ),
                storyConfig.emotion,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    emotion: value,
                  });
                }
              )}
            {index === 6 &&
              renderSlide(
                t('linearCreationStory.item.title'),
                t('linearCreationStory.item.text'),
                getLinearChoiceItems(
                  t,
                  storyConfig,
                  LinearStoryResolveType.ITEMS
                ),
                storyConfig.item,
                (value: string) => {
                  updateStoryConfig({
                    ...storyConfig,
                    item: value,
                  });
                }
              )}
            {index === 7 && history.push(ROUTES.LINEAR_STORY)}
          </SwiperStyled>
        </Background>
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  background-image: url('/assets/storyPageAssets/crea-story.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
`;

const SwiperStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 60px;
`;

const Title = styled(Typography)`
  font-size: 2.5em;
  font-weight: bold;
  align-self: center;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${colors.textPrimary};
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;
