import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const {
    firstName = '',
    lastName = '',
    email = '',
    password = '',
    confirmPassword = '',
  } = values;
  return {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    confirmPassword: values.confirmPassword,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};

  if (Validator.isEmpty(values.firstName))
    errors.firstName = 'Veuillez indiquer votre prénom';
  if (Validator.isEmpty(values.lastName))
    errors.lastName = 'Veuillez indiquer votre nom';
  if (!Validator.isEmail(values.email))
    errors.email = 'Veuillez saisir un email valide';
  if (Validator.isEmpty(values.password))
    errors.password = 'Veuillez introduire votre mot de passe';
  if (values.password !== values.confirmPassword)
    errors.confirmPassword = 'Les mots de passes sont différents';

  return errors;
}
