import { Input, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, MEDIA_QUERIES } from '../../themes';

interface Props {
  title: string;
  text: string;
  defaultName?: string;
  onChoice: (value: string) => void;
  next: () => void;
  prev: () => void;
}

export function StoryCreationNamePage(props: Props): JSX.Element {
  const { title, text, defaultName, onChoice, next } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(defaultName ? defaultName : '');
  const [error, setError] = useState(false);

  function choiceSelection() {
    if (inputValue === '') return setError(true);
    else setError(false);
    onChoice(inputValue);
    next();
  }

  return (
    <Main>
      <Title>{title}</Title>
      {text !== '' && <Text>{text}</Text>}
      <InputContainer>
        <InputStyled
          onPressEnter={choiceSelection}
          size='large'
          defaultValue={defaultName}
          placeholder={t('linearCreationStory.name.placeholderName')}
          onChange={(
            newValue: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setInputValue(newValue.target?.value)}
        />
        {error && <ErrorText>{t('linearCreationStory.name.error')}</ErrorText>}
      </InputContainer>
      <ButtonContainer>
        <ButtonStyled onClick={choiceSelection}>
          {t('component.button.validation')}
        </ButtonStyled>
      </ButtonContainer>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px;

  @media ${MEDIA_QUERIES.MOBILE} {
    margin: 20px;
  }
`;

const InputStyled = styled(Input)`
  border-radius: 50px;
  padding: 20px 0 20px 30px;
  text-align: left;
  font-size: 1.3em;

  @media ${MEDIA_QUERIES.MOBILE} {
    font-size: 1em;
    padding: 10px 0 10px 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.buttonBackground};
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 1.4em;
  font-weight: bold;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    font-size: 1em;
    font-weight: normal;
  }
`;

const Title = styled(Typography)`
  font-size: 1.8em;
  font-weight: bold;
  color: ${colors.textPrimary};
  align-self: center;
`;

const Text = styled(Typography)`
  font-size: 1.2em;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${colors.textPrimary};
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;

const ErrorText = styled(Typography)`
  color: red;
  padding-left: 30px;
`;
