import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuthContext } from '../../../contexts/AuthContext';
import history from '../../../router/history';
import { ROUTES } from '../../../router/Router';
import { colors } from '../../../themes';
import { MEDIA_QUERIES } from '../../../themes/constants';
import { LanguageSelector } from './LanguageSelector';
import { Logo } from './Logo';

interface Props {}

export function Header(props: Props): JSX.Element {
  const {} = props;

  const { t } = useTranslation();
  const { isAuthenticated } = useAuthContext();

  return (
    <Main>
      <LeftContainer onClick={() => history.push(ROUTES.HOME)}>
        <Logo />
      </LeftContainer>
      <RightContainer>
        <ActionButton
          onClick={() => history.push(ROUTES.LINEAR_STORY_CREATION)}
        >
          {t('header.option1')}
        </ActionButton>
        <ActionButton
          onClick={() => history.push(ROUTES.INTERACTIVE_STORY_CREATION)}
        >
          {t('header.option2')}
        </ActionButton>
        <ActionButton onClick={() => history.push(ROUTES.SUBSCRIPTION)}>
          {t('header.option3')}
        </ActionButton>
        {isAuthenticated ? (
          <ActionButton onClick={() => history.push(ROUTES.PROFILE)}>
            {t('header.option4-alt')}
          </ActionButton>
        ) : (
          <ActionButton onClick={() => history.push(ROUTES.LOGIN)}>
            {t('header.option4')}
          </ActionButton>
        )}
      </RightContainer>
      <LanguageSelectorContainer>
        <LanguageSelector />
      </LanguageSelectorContainer>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  min-width: 100vw;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.greyLight};
  padding: 10px 0px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 0.25;
  justify-content: center;
  align-items: center;
  padding: 0px 31px;
  :hover {
    cursor: pointer;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const LanguageSelectorContainer = styled.div`
  flex: 0.1;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const ActionButton = styled.div`
  flex: 1;
  height: 100%;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    font-size: 0.8em;
  }
`;
