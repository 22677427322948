import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  password: string;
  confirmPassword: string;
}

export interface FormValues {
  password: string;
  confirmPassword: string;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const { password = '', confirmPassword = '' } = values;
  return {
    password,
    confirmPassword,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    password: values.password,
    confirmPassword: values.confirmPassword,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};
  if (Validator.isEmpty(values.password))
    errors.password = 'Veuillez introduire votre mot de passe';
  if (values.confirmPassword !== values.password)
    errors.confirmPassword = 'Les mots de passes sont différents';
  return errors;
}
