import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div role='alert'>
      <p>{t('errorBoundary.title')}</p>
      <pre>{error}</pre>
      <button onClick={resetErrorBoundary}>
        {t('errorBoundary.tryAgain')}
      </button>
    </div>
  );
}
