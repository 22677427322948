import { Image, Typography } from 'antd';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { useMountEffect } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import ApiService from '../../services/network/apiService';
import { formatMultilineTextToHtml } from '../../services/utils';
import { colors, IMAGE_FALLBACK } from '../../themes';
import { MEDIA_QUERIES } from '../../themes/constants';
import { ApiPrice } from '../../types/api/ApiPrice';
import { Layout } from '../Layout';

const FILTERED_PRICES_DEFAULT_VALUES: ApiPrice[] = [
  {
    id: '',
    billingCycle: 'month',
    price: 350,
  },
  { id: '', billingCycle: 'year', price: 2990 },
];

export function SubscriptionPage(): JSX.Element {
  const { t } = useTranslation();
  const [stripePlans, setStripePlans] = useState<ApiPrice[]>(
    FILTERED_PRICES_DEFAULT_VALUES
  );

  useMountEffect(() => {
    async function getPlans() {
      const plans: ApiPrice[] = await ApiService.getStripePrices();
      const orderedPlans = plans
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .sort((plan1: ApiPrice, plan2: ApiPrice) => plan1.price - plan2.price);
      console.log('SubscriptionPage', plans, orderedPlans);
      setStripePlans(orderedPlans);
    }
    getPlans();
  });

  function htmlFormat(text: string) {
    return ReactHtmlParser(formatMultilineTextToHtml(text));
  }

  function offerRender(
    title: string,
    price: string,
    details: string,
    stripePriceId: string,
    oldPrice = '',
    isUltimatePremium = false
  ) {
    return (
      <OfferContainer>
        <OfferTitle>{htmlFormat(title)}</OfferTitle>
        <OfferPrice>{htmlFormat(price)}</OfferPrice>
        {oldPrice !== '' && (
          <OfferOldPriceContainer>
            <OfferOldPrice>{htmlFormat(oldPrice)}</OfferOldPrice>
          </OfferOldPriceContainer>
        )}
        <OfferDetailsContainer>
          <OfferDetails>{htmlFormat(details)}</OfferDetails>
        </OfferDetailsContainer>
        {isUltimatePremium && (
          <PremiumImageContainer>
            <PremiumImage
              preview={false}
              width={100}
              src='/assets/premium_dragon.svg'
            />
          </PremiumImageContainer>
        )}
        <ButtonContainer>
          <ButtonPrimary
            onClick={() => {
              history.push(`${ROUTES.PAYMENT.split(':')[0]}${stripePriceId}`);
            }}
          >
            {t('subscription.offer.button')}
          </ButtonPrimary>
        </ButtonContainer>
      </OfferContainer>
    );
  }

  function marketingRender(title: string, details: string, img: string) {
    return (
      <InfoContainer>
        <InfoText>{htmlFormat(title.toUpperCase())}</InfoText>
        <InfoText>{htmlFormat(details)}</InfoText>
        <InfoImgContainer>
          <InfoImage src={img} preview={false} fallback={IMAGE_FALLBACK} />
        </InfoImgContainer>
      </InfoContainer>
    );
  }

  const [lowestPlan, highestPlan] = stripePlans;

  return (
    <Layout>
      <Main>
        <IntroContainer>
          <Title>{t('subscription.title')}</Title>
          <SubTitle>{t('subscription.subtitle1')}</SubTitle>
          <SubTitle>{t('subscription.subtitle2')}</SubTitle>
        </IntroContainer>
        <AllOffersContainer>
          <OffersContainer>
            {offerRender(
              t('subscription.offer.offer1.title'),
              `${(lowestPlan.price / 100).toFixed(2).toString()}€/mois`,
              t('subscription.offer.offer1.detail'),
              lowestPlan.id,
              t('subscription.offer.offer1.oldPrice')
            )}
            {offerRender(
              t('subscription.offer.offer3.title'),
              `${(highestPlan.price / 100).toFixed(2).toString()}€/an`,
              t('subscription.offer.offer3.detail'),
              highestPlan.id,
              t('subscription.offer.offer3.oldPrice'),
              true
            )}
          </OffersContainer>
          <OffersInfos>{htmlFormat(t('subscription.offer.infos'))}</OffersInfos>
        </AllOffersContainer>
        <MarketingContainer>
          {marketingRender(
            t('subscription.offer.marketing.bloc1.title'),
            t('subscription.offer.marketing.bloc1.details'),
            'https://dl.dropboxusercontent.com/s/atcasgjmw1uwfi1/1PREMIUM_CREATION.png?raw=1'
          )}
          {marketingRender(
            t('subscription.offer.marketing.bloc2.title'),
            t('subscription.offer.marketing.bloc2.details'),
            'https://dl.dropboxusercontent.com/s/gtkvrib7ycisppi/2PREMIUM_QUALITY_SCREEN%20TIME.png?raw=1'
          )}
          {marketingRender(
            t('subscription.offer.marketing.bloc3.title'),
            t('subscription.offer.marketing.bloc3.details'),
            'https://dl.dropboxusercontent.com/s/lgs6cjhx73o6ni8/3PREMIUM_PEDAGOGIE.png?raw=1'
          )}
        </MarketingContainer>
        <TextBottomContainer>
          <Title>{t('subscription.offer.punchline1')}</Title>
          <SubTitle>{t('subscription.offer.punchline2')}</SubTitle>
        </TextBottomContainer>
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 55px;
  margin-top: 25px;

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 0px 10px;
    text-align: center;
  }
`;

const AllOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

const OffersContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const OfferContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 350px;
  flex-direction: column;
  background-color: ${colors.buttonBackground};
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  margin: 0 50px 25px 50px;
  margin-top: -35px;

  @media ${MEDIA_QUERIES.MOBILE} {
    margin-bottom: 100px;
  }
`;

const MarketingContainer = styled.div`
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  margin-top: 25px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InfoImgContainer = styled.div`
  display: flex;
  margin-top: auto;
`;

const TextBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: center;
`;

const PremiumImageContainer = styled.div`
  display: flex;
  margin-left: -45px;
  :hover {
    cursor: pointer;
  }
`;

const OfferOldPriceContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const OfferDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const PremiumImage = styled(Image)`
  position: absolute;
  left: 0;
`;

const InfoImage = styled(Image)`
  padding: 0 10px;
`;

const Title = styled(Typography)`
  font-size: 2em;
`;

const SubTitle = styled(Typography)`
  font-size: 1.7em;
  text-align: center;
`;

const OfferTitle = styled(Typography)`
  font-size: 1.7em;
`;

const OfferPrice = styled(Typography)`
  font-size: 2.3em;
  font-weight: bold;
`;

const OfferOldPrice = styled(Typography)`
  font-size: 1.6em;
  background-color: transparent;
  text-decoration: line-through;
`;

const OfferDetails = styled(Typography)`
  font-size: 1.3em;
  margin-bottom: 35px;
`;

const OffersInfos = styled(Typography)`
  text-align: center;
  font-size: 1.4em;
  margin: 0 65px 15px 65px;
`;

const InfoText = styled(Typography)`
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 15px;
`;
