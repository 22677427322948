import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import { Image, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import styled from 'styled-components';
import { useAuthContext } from '../../contexts/AuthContext';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import { colors, IMAGE_FALLBACK, MEDIA_QUERIES } from '../../themes';
import { LinearStoryChoiceItem } from '../../types';

interface Props {
  title: string;
  text: string;
  choices: LinearStoryChoiceItem[];
  currentlySelected: string;
  onChoice: (value: string) => void;
  next: () => void;
  prev: () => void;
}

export function StoryCreationChoicePage(props: Props): JSX.Element {
  const { title, text, choices, currentlySelected, onChoice, next, prev } =
    props;
  const { t } = useTranslation();

  const { currentUser } = useAuthContext();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMobile = useMediaPredicate(MEDIA_QUERIES.MOBILE);

  function choiceSelection(selection: LinearStoryChoiceItem) {
    onChoice(selection.value);
    next();
  }

  function onModalSubscribe() {
    history.push(ROUTES.SUBSCRIPTION);
    setIsModalVisible(false);
  }

  function onModalCancel() {
    setIsModalVisible(false);
  }

  return (
    <Main>
      <div style={{ display: 'flex' }}>
        <GoBackContainer onClick={prev}>
          <ArrowLeftOutlined />
          <GoBackText>{t('component.button.goBack')}</GoBackText>
        </GoBackContainer>
      </div>

      <Title>{title}</Title>
      {text !== '' && <Text>{text}</Text>}
      <ListItem>
        {choices.map((choice: LinearStoryChoiceItem) => {
          return (
            <Item key={choice.value}>
              <ImageStyled
                onClick={() => choiceSelection(choice)}
                isselected={choice.value === currentlySelected}
                height={isMobile ? 75 : 150}
                width={isMobile ? 75 : 150}
                preview={false}
                src={choice.asset}
                fallback={IMAGE_FALLBACK}
              />
              {choice.premium && !currentUser?.subscription?.isSubscribed && (
                <PremiumMaskItem onClick={(e) => e.preventDefault()}>
                  <PremiumMask onClick={() => setIsModalVisible(true)}>
                    <LockOutlined />
                  </PremiumMask>
                </PremiumMaskItem>
              )}
              <ItemLabel>{choice.title}</ItemLabel>
            </Item>
          );
        })}
      </ListItem>
      <ModalStyled
        title={t('linearCreationStory.modal.title')}
        visible={isModalVisible}
        onOk={onModalSubscribe}
        onCancel={onModalCancel}
        okText={t('linearCreationStory.modal.buttons.subscribe')}
        cancelText={t('linearCreationStory.modal.buttons.cancel')}
      >
        <p>{t('linearCreationStory.modal.content')}</p>
      </ModalStyled>
    </Main>
  );
}

interface AvatarStyledProps {
  isselected: boolean;
  onClick: () => void;
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 33%;
  flex: 1;
  align-items: center;
  margin: 15px 0;
`;

const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  background-color: ${colors.buttonBackground};
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
`;

const GoBackText = styled.div`
  margin: 0 10px;
`;

const ImageStyled = styled(Image)`
  border: ${(props: AvatarStyledProps) =>
    props.isselected ? `5px solid ${colors.secondary}` : ''};

  border-radius: 50%;
  :hover {
    border: 5px solid ${colors.secondary};
    cursor: pointer;
  }
`;

const Title = styled(Typography)`
  font-size: 1.8em;
  font-weight: bold;
  color: ${colors.textPrimary};
  align-self: center;
`;

const Text = styled(Typography)`
  font-size: 1.2em;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${colors.textPrimary};
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;

const ItemLabel = styled(Typography)`
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;

const PremiumMask = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(2px);
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  font-size: 48px;

  :hover {
    cursor: pointer;
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    height: 75px;
    width: 75px;
    font-size: 24px;
  }
`;

const PremiumMaskItem = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  font-size: 48px;

  :hover {
    cursor: unset;
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    height: 75px;
    width: 75px;
    font-size: 24px;
  }
`;
