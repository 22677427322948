import React from 'react';
import { Route, Router } from 'react-router-dom';
import {
  ContactPage,
  FAQPage,
  ForgetPasswordPage,
  HomePage,
  LegalNoticePage,
  LoginPage,
  MySubscriptionPage,
  PaymentPage,
  PrivacyPolicyPage,
  ProfilePage,
  RegisterPage,
  ResetPasswordPage,
  StoryCreationPage,
  StoryInteractivePage,
  StoryInteractiveSelectionPage,
  StoryLinearPage,
  SubscriptionPage,
} from '../pages';
import createBrowserHistory from './history';
import { SwitchNotFoundHandler } from './SwitchNotFoundHandler';
import { SwitchProcessHandler } from './SwitchProcessHandler';

export const ROUTES = {
  HOME: '/',
  LINEAR_STORY_CREATION: '/create-linear-story',
  LOGIN: '/login',
  REGISTER: '/register',
  INTERACTIVE_STORY_CREATION: '/create-interactive-story',
  LINEAR_STORY: '/linear-story',
  INTERACTIVE_STORY: '/interactive-story',
  SUBSCRIPTION: '/subscription',
  PAYMENT: '/payment/:stripePriceId',
  LEGAL_NOTICE: '/terms',
  PRIVACY_POLICY: '/privacy-policy',
  FAQ: '/faq',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  PROFILE_SUBSCRIPTION: '/profile-subscription',
  CONTACT: '/contact',
};

const AppRouter = (): JSX.Element => {
  return (
    <Router history={createBrowserHistory}>
      <SwitchProcessHandler>
        <SwitchNotFoundHandler>
          <Route path={ROUTES.HOME} exact component={HomePage} />
          <Route path={ROUTES.LOGIN} exact component={LoginPage} />
          <Route path={ROUTES.REGISTER} exact component={RegisterPage} />
          <Route
            path={ROUTES.LINEAR_STORY_CREATION}
            exact
            component={StoryCreationPage}
          />
          <Route
            path={ROUTES.INTERACTIVE_STORY_CREATION}
            exact
            component={StoryInteractiveSelectionPage}
          />
          <Route path={ROUTES.LINEAR_STORY} exact component={StoryLinearPage} />
          <Route
            path={ROUTES.INTERACTIVE_STORY}
            exact
            component={StoryInteractivePage}
          />
          <Route
            path={ROUTES.SUBSCRIPTION}
            exact
            component={SubscriptionPage}
          />
          <Route path={ROUTES.PAYMENT} component={PaymentPage} />
          <Route path={ROUTES.LEGAL_NOTICE} component={LegalNoticePage} />
          <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
          <Route path={ROUTES.FAQ} component={FAQPage} />
          <Route path={ROUTES.FORGET_PASSWORD} component={ForgetPasswordPage} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
          <Route path={ROUTES.PROFILE} component={ProfilePage} />
          <Route path={ROUTES.CONTACT} component={ContactPage} />
          <Route
            path={ROUTES.PROFILE_SUBSCRIPTION}
            component={MySubscriptionPage}
          />
        </SwitchNotFoundHandler>
      </SwitchProcessHandler>
    </Router>
  );
};
export default AppRouter;
