import { Button as ButtonAntd } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

interface Props extends React.ComponentProps<typeof ButtonAntd> {}

export const Button: React.FC<Props> = (props) => {
  return <ButtonStyled {...props} />;
};

const ButtonStyled = styled(ButtonAntd)`
  background-color: ${colors.primary};
  border: 0;
  color: white;
  font-family: Montserrat;
  flex-wrap: wrap;
`;
