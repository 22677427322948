import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  email: string;
}

export interface FormValues {
  email: string;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const { email = '' } = values;
  return {
    email,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    email: values.email,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};
  if (!Validator.isEmail(values.email))
    errors.email = 'Veuillez saisir un email valide';
  return errors;
}
