/* eslint-disable indent */
import { toast, TypeOptions } from 'react-toastify';

export function useLocalNotification(): {
  notify: (message: string, type: TypeOptions) => void;
} {
  function notify(message: string, type: TypeOptions) {
    toast(message, { type });
  }
  return { notify };
}
