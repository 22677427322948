import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface SwitchProcessHandlerProps {
  children: ReactNode;
}

export function SwitchProcessHandler(
  props: SwitchProcessHandlerProps
): JSX.Element {
  const { children } = props;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [pathname]);

  return <>{children}</>;
}
