/* eslint-disable indent */
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { Button, Input } from 'antd';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ErrorMessage } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocalNotification } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import ApiService from '../../services/network/apiService';
import { colors, MEDIA_QUERIES } from '../../themes';
import { ApiPrice } from '../../types/api/ApiPrice';
import { Logo } from '../Layout/Header/Logo';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
} as StripeCardElementOptions;

interface Props {
  stripePrice: ApiPrice;
}

export function PaymentPageForm(props: Props): JSX.Element {
  const {
    id: stripePriceId,
    price: initialPrice,
    billingCycle,
  } = props.stripePrice;

  const { refreshUser } = useAuthContext();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { notify } = useLocalNotification();

  const [loading, setLoading] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isCouponValidated, setIsCouponValidated] = useState<boolean | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [price, setPrice] = useState(initialPrice);
  const [coupon, setCoupon] = useState(''); // TODO: change this

  async function calculatePrice() {
    const calculatedPrice = await ApiService.calculatePrice(
      stripePriceId,
      coupon
    );
    if (calculatedPrice !== price)
      notify(t('payment.notify.priceUpdate'), 'success');
    setPrice(calculatedPrice);
  }

  async function onSubmit(event?: FormEvent<HTMLFormElement>) {
    event && event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setErrorMessage(
        "Le prestataire de paiement n'est pas disponible. Veuillez réessayer plus tard."
      );
      setLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setErrorMessage(
        "Le prestataire de paiement n'est pas disponible. Veuillez réessayer plus tard."
      );
      setLoading(false);
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      setErrorMessage(t('payment.failed'));
      setLoading(false);
    } else {
      try {
        await ApiService.purchase(stripePriceId, paymentMethod, coupon);
        await refreshUser();
        notify(t('payment.notify.success'), 'success');
        history.push(ROUTES.HOME);
      } catch (e) {
        setErrorMessage(t('payment.failed'));
        setLoading(false);
      }
    }
  }

  function renderCouponButtonContent() {
    if (isCouponLoading)
      return <LoadingOutlined style={{ color: '#ffffff' }} />;
    if (isCouponValidated === null)
      return <ReloadOutlined style={{ color: '#000000' }} />;
    return isCouponValidated ? (
      <CheckCircleOutlined style={{ color: '#ffffff' }} />
    ) : (
      <CloseCircleOutlined style={{ color: '#ffffff' }} />
    );
  }

  function resolveCouponButtonBackgroundColor() {
    if (isCouponLoading) return '#F6AF65';
    if (isCouponValidated === null) return '#EDF8C9';
    return isCouponValidated ? '#ACF7C1' : '#EE6352';
  }

  async function validateCoupon() {
    setIsCouponLoading(true);
    const isCouponValid = await ApiService.checkCoupon(coupon);
    setIsCouponValidated(isCouponValid);
    setIsCouponLoading(false);
    await calculatePrice();
  }

  const couponButtonBackgroundColor = resolveCouponButtonBackgroundColor();

  return (
    <Main>
      <LocalHeader>
        <GoBackContainer onClick={() => history.push(ROUTES.SUBSCRIPTION)}>
          <ArrowLeftOutlined />
          <GoBackText>{t('component.button.goBack')}</GoBackText>
        </GoBackContainer>
        <Logo />
      </LocalHeader>
      <CardContainer>
        <TitleStyled>
          {t('payment.offer', {
            billingCycle: t(`payment.billingCycle.${billingCycle}`),
            price: (price / 100).toFixed(2),
          })}
        </TitleStyled>
        <TextStyled>{t('payment.text')}</TextStyled>
        <form onSubmit={onSubmit}>
          <CardElementStyled
            onChange={() => setErrorMessage('')}
            options={CARD_OPTIONS}
          />
        </form>
        <CouponContainer>
          <CouponInput
            placeholder={t('payment.coupon.placeholder')}
            value={coupon}
            onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
              event.stopPropagation();
              validateCoupon();
              return false;
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCoupon(event.target.value)
            }
          />
          <CouponButton
            backgroundcolor={couponButtonBackgroundColor}
            onClick={validateCoupon}
          >
            {renderCouponButtonContent()}
          </CouponButton>
        </CouponContainer>
        <ButtonStyled
          type='submit'
          disabled={!stripe || loading}
          onClick={() => onSubmit()}
        >
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            t('payment.button', { price: (price / 100).toFixed(2) })
          )}
        </ButtonStyled>
        <ErrorMessage color={'white'} display={errorMessage !== ''}>
          {errorMessage}
        </ErrorMessage>
      </CardContainer>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: scroll;
  padding: 60px 150px;
  background-color: #7795f8;
  overflow-y: scroll;

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 3px;
  }
`;

const LocalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: #6772e5;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 35px;
  margin-bottom: 50px;

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 10px;
  }
`;

const CardElementStyled = styled(CardElement)`
  margin: 0 0 20px;
  padding: 15px 15px;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const ButtonStyled = styled.button`
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
  border: 0;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 40px;
  min-height: 40px;
  margin: 40px 0 15px;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
`;

const GoBackContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.buttonBackground};
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0px 3px 9px 0px #c4c4c4;
  :hover {
    box-shadow: 0px 0px 5px 0px #c4c4c4;
    cursor: pointer;
  }
`;

const GoBackText = styled.div`
  margin: 0 10px;
`;

const TitleStyled = styled.p`
  font-size: 1.5rem;
  color: white;
`;

const TextStyled = styled.p`
  color: white;
`;

const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
  min-height: 40px;
  max-height: 40px;
`;

const CouponInput = styled(Input)`
  background-color: #7795f8;
  perspective: 1000px;
  min-height: 40px;
  text-justify: auto;
  color: white !important;
  border: none;
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%),
    inset 0 1px 0 #829fff;
  border-radius: 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  :focus {
    border: none;
  }

  ::placeholder {
    color: #c4f0ff !important;
  }
`;

interface CouponButtonProps {
  backgroundcolor: string;
}

const CouponButton = styled(Button)`
  ${({ backgroundcolor }: CouponButtonProps) =>
    `background-color: ${backgroundcolor} !important;`}

  min-height: 40px;
  border: none;
  border-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  :hover {
    ${({ backgroundcolor }: CouponButtonProps) =>
      `background-color: ${backgroundcolor} !important;`}
    border: none;
    color: unset;
  }
  :focus {
    ${({ backgroundcolor }: CouponButtonProps) =>
      `background-color: ${backgroundcolor} !important;`}
    border: none;
    color: unset;
  }
`;
