import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonSecondary, StoryDisplay } from '../../components';
import { useInteractiveStoryContext } from '../../contexts/InteractiveStoryContext';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import {
  displayInteractiveStory,
  getCurrentInteractiveChoice,
  getNextInteractiveChoices,
  isInteractiveStoryFinished,
} from '../../services/interactiveStory';
import { MEDIA_QUERIES } from '../../themes';
import { ApiChoiceNext } from '../../types';
import { Layout } from '../Layout';

export function StoryInteractivePage(): JSX.Element {
  const {
    storyPath,
    storyName,
    makeChoice,
    resetStory,
  } = useInteractiveStoryContext();
  const { t } = useTranslation();

  function goBack() {
    resetStory();
    history.push(ROUTES.INTERACTIVE_STORY_CREATION);
  }

  function renderNextChoices() {
    return (
      <>
        {getNextInteractiveChoices(storyPath).map(
          (nextChoice: ApiChoiceNext) => (
            <ButtonSecondaryStyled
              key={nextChoice.id}
              onClick={() => makeChoice(nextChoice.id)}
            >
              {nextChoice.title}
            </ButtonSecondaryStyled>
          )
        )}
      </>
    );
  }

  function renderEndingActions() {
    return (
      <>
        <ButtonSecondaryStyled onClick={goBack}>
          {t('component.button.goBackWithHistory')}
        </ButtonSecondaryStyled>
        <ButtonSecondaryStyled onClick={() => history.push(ROUTES.HOME)}>
          {t('component.button.goBackInteractiveStory')}
        </ButtonSecondaryStyled>
      </>
    );
  }

  return (
    <Layout>
      <Main>
        <StoryDisplay
          asset={getCurrentInteractiveChoice(storyPath)?.asset || ''}
          title={storyName}
          text={displayInteractiveStory(storyPath)}
          renderActions={
            isInteractiveStoryFinished(storyPath)
              ? renderEndingActions
              : renderNextChoices
          }
        />
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const ButtonSecondaryStyled = styled(ButtonSecondary)`
  @media ${MEDIA_QUERIES.MOBILE} {
    width: 85%;
    font-size: 1em;
    font-weight: normal;
    padding: 8px;
    text-align: center;
  }
`;
