import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import styled from 'styled-components';
import { ButtonSecondary, StoryDisplay } from '../../components';
import { useLanguageContext } from '../../contexts/LanguageContext';
import { useStoryCreationContext } from '../../contexts/StoryCreationContext';
import { useMountEffect } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import {
  generateEmptyStoryConfig,
  generateTextFromStoryConfig,
  getBackgroundAssetForStoryConfig,
  getStoryTitleForStoryConfig,
  validateStoryConfig,
} from '../../services/linearStory';
import { MEDIA_QUERIES } from '../../themes';
import { Layout } from '../Layout';

export function StoryLinearPage(): JSX.Element {
  const [storyConfigCache, setStoryConfigCache] = useState(
    generateEmptyStoryConfig()
  );
  const { t } = useTranslation();
  const { storyConfig, updateStoryConfig } = useStoryCreationContext();
  const { currentLanguage } = useLanguageContext();

  const isMobile = useMediaPredicate(MEDIA_QUERIES.MOBILE);

  const backgroundAsset = getBackgroundAssetForStoryConfig(
    t,
    storyConfigCache,
    isMobile
  );

  const [title, setTitle] = useState(
    getStoryTitleForStoryConfig(t, storyConfigCache)
  );
  const [text, setText] = useState(
    generateTextFromStoryConfig(t, storyConfigCache)
  );

  useEffect(() => {
    setTitle(getStoryTitleForStoryConfig(t, storyConfigCache));
    setText(generateTextFromStoryConfig(t, storyConfigCache));
  }, [currentLanguage, storyConfigCache, t]);

  useMountEffect(() => {
    setStoryConfigCache(storyConfig);
    updateStoryConfig(generateEmptyStoryConfig());
  });

  function goBackWithHistory(): void {
    updateStoryConfig({
      ...storyConfigCache,
      emotion: '__EMPTY',
      item: '__EMPTY',
    });
    history.push(ROUTES.LINEAR_STORY_CREATION);
  }

  function goBackLinearStory(): void {
    updateStoryConfig({
      ...generateEmptyStoryConfig(),
      name: storyConfigCache.name,
    });
    history.push(ROUTES.LINEAR_STORY_CREATION);
  }

  function renderEndingButtons() {
    return (
      <>
        <ButtonSecondaryStyled onClick={goBackWithHistory}>
          {t('component.button.goBackWithHistory')}
        </ButtonSecondaryStyled>
        <ButtonSecondaryStyled onClick={goBackLinearStory}>
          {t('component.button.goBackLinearStory')}
        </ButtonSecondaryStyled>
      </>
    );
  }

  return (
    <Layout>
      <Main>
        {validateStoryConfig(storyConfigCache) && (
          <StoryDisplay
            asset={backgroundAsset}
            title={title}
            text={text}
            renderActions={renderEndingButtons}
            disableInsideScroll
          />
        )}
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const ButtonSecondaryStyled = styled(ButtonSecondary)`
  @media ${MEDIA_QUERIES.MOBILE} {
    width: 85%;
    font-size: 1em;
    font-weight: normal;
    padding: 8px;
    text-align: center;
  }
`;
