/* eslint-disable indent */
import { formatDate } from '@bobjs/date';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonPrimary } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocalNotification } from '../../hooks';
import ApiService from '../../services/network/apiService';
import { formatHTMLText } from '../../services/utils';
import { Layout, SettingsLayout } from '../Layout';

interface MySubscriptionPageProps {}

export function MySubscriptionPage(
  props: MySubscriptionPageProps
): JSX.Element {
  const {} = props;
  const { t } = useTranslation();
  const { currentUser, refreshUser } = useAuthContext();
  const { notify } = useLocalNotification();

  const [canceling, setCanceling] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  async function cancelSubscription() {
    setCanceling(true);
    await ApiService.cancelSubscription();
    notify(t('mySubscription.unsubbed'), 'success');
    refreshUser();
    setCanceling(false);
    setIsModalVisible(false);
  }

  function onModalCancel() {
    setIsModalVisible(false);
  }

  return (
    <Layout>
      <SettingsLayout>
        <Main>
          {currentUser?.subscription?.isCanceled &&
          currentUser?.subscription?.cancelDate &&
          currentUser?.subscription?.isSubscribed ? (
            <p>
              {formatHTMLText(
                t('mySubscription.canceledSub', {
                  until: formatDate(
                    new Date(currentUser.subscription.cancelDate)
                  ),
                })
              )}
            </p>
          ) : currentUser?.subscription?.isSubscribed ? (
            <>
              <p>
                {formatHTMLText(
                  t('mySubscription.currentSub.type', {
                    billingCycle: t(
                      `payment.billingCycle.${currentUser.subscription.subscriptionPrice.billingCycle}`
                    ),
                  })
                )}
              </p>
              <p>
                {formatHTMLText(
                  t('mySubscription.currentSub.billingDate', {
                    date: currentUser.subscription.nextPaymentDate
                      ? formatDate(
                          new Date(currentUser.subscription.nextPaymentDate)
                        )
                      : '',
                  })
                )}
              </p>
              <p>{formatHTMLText(t('mySubscription.cancelText'))}</p>
              <ButtonPrimaryStyled
                disabled={canceling}
                onClick={() => setIsModalVisible(true)}
              >
                {t('mySubscription.cancel')}
              </ButtonPrimaryStyled>
            </>
          ) : (
            <p>{formatHTMLText(t('mySubscription.notSubbed'))}</p>
          )}
        </Main>
        <ModalStyled
          title={t('mySubscription.modal.title')}
          visible={isModalVisible}
          onOk={cancelSubscription}
          onCancel={onModalCancel}
          okText={t('mySubscription.modal.buttons.ok')}
          cancelText={t('mySubscription.modal.buttons.cancel')}
        >
          <p>{t('mySubscription.modal.content')}</p>
        </ModalStyled>
      </SettingsLayout>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 200px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
  max-width: 350px;
`;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px;
  }
`;
