import React from 'react';
import { AuthProvider } from './AuthContext';
import { InteractiveStoryProvider } from './InteractiveStoryContext';
import { LanguageProvider } from './LanguageContext';
import { StoryCreationProvider } from './StoryCreationContext';

interface Props {
  children: React.ReactNode;
}

const StateProviders = ({ children }: Props): JSX.Element => {
  return (
    <LanguageProvider>
      <AuthProvider>
        <StoryCreationProvider>
          <InteractiveStoryProvider>{children}</InteractiveStoryProvider>
        </StoryCreationProvider>
      </AuthProvider>
    </LanguageProvider>
  );
};

export default StateProviders;
