import React, { useEffect, useState } from 'react';
import { useMountEffect } from '../hooks';
import ApiService from '../services/network/apiService';
import { http } from '../services/network/httpService';
import { User } from '../types';

interface State {
  currentUser: User | null;
  token: string;
  isAuthenticated: boolean;
}

interface Context extends State {
  setToken: (token: string) => void;
  removeToken: () => void;
  refreshUser: () => Promise<void>;
}

const defaultState: State = {
  currentUser: null,
  token: '',
  isAuthenticated: false,
};

const AuthContext = React.createContext<Context>({
  ...defaultState,
  setToken: () => {
    throw new Error('AuthContext.setToken has not been set');
  },
  removeToken: () => {
    throw new Error('AuthContext.removeToken has not been set');
  },
  refreshUser: () => {
    throw new Error('AuthContext.refreshUser has not been set');
  },
});

function useAuthProvider() {
  const [token, setStateToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(token !== '');
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  http.setToken(token);

  async function refreshUser() {
    if (token === '') return;
    try {
      const user = await ApiService.me();
      setCurrentUser(user);
      return;
    } catch (e) {
      removeToken();
    }
  }

  function setToken(newToken: string) {
    setStateToken(newToken);
    localStorage.setItem('token', newToken);
    http.setToken(newToken);
  }

  async function removeToken() {
    localStorage.clear();
    setStateToken('');
    setCurrentUser(null);
  }

  useMountEffect(() => {
    refreshUser();
  });

  useEffect(() => {
    refreshUser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    setIsAuthenticated(token !== '');
  }, [token]);

  return {
    currentUser,
    token,
    setToken,
    removeToken,
    refreshUser,
    isAuthenticated,
  };
}

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const context: Context = useAuthProvider();
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): Context => React.useContext(AuthContext);
