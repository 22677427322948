import { Select, Switch, Typography } from 'antd';
import { FormApi, FORM_ERROR } from 'final-form';
import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Button,
  ErrorMessage,
  FormField,
  InputPassword,
  InputText,
  LabelInput,
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocalNotification } from '../../hooks';
import { AvailableLanguageAssets, ILanguage } from '../../i18n/init';
import ApiService from '../../services/network/apiService';
import { colors, MEDIA_QUERIES } from '../../themes';
import { Layout, SettingsLayout } from '../Layout';
import {
  FormValues,
  getFormInitialValues,
  getFormStatePayload,
  validateFormValues,
} from './ProfilePage.formstate';

interface ProfilePageProps {}

export function ProfilePage(props: ProfilePageProps): JSX.Element {
  const {} = props;
  const { t } = useTranslation();
  const { notify } = useLocalNotification();

  const { currentUser, refreshUser } = useAuthContext();

  async function onSubmit(
    _: unknown,
    formApi: FormApi<FormValues, FormValues>
  ): Promise<{ [FORM_ERROR]: string | React.ReactNode } | undefined> {
    const formState = formApi.getState();
    const payload = getFormStatePayload(formState);

    try {
      const {
        firstName,
        lastName,
        email,
        password,
        languages,
        emailOptIn,
        country,
      } = payload;
      await ApiService.patchProfile({
        firstName,
        lastName,
        email,
        password,
        languages,
        emailOptIn,
        country,
      });
      refreshUser();
      notify(t('profile.success'), 'success');
    } catch (e) {
      return { [FORM_ERROR]: 'Update Failed' };
    }
  }

  return (
    <Layout>
      <SettingsLayout>
        <Title>{t('profile.title')}</Title>
        <Form
          initialValues={getFormInitialValues({ ...currentUser })}
          validate={validateFormValues}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            submitting,
            values,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any) => {
            return (
              <FormContainer onSubmit={handleSubmit}>
                <FormField
                  name='firstName'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.firstName.title')}</LabelInput>
                        <InputText
                          placeholder={t('profile.firstName.placeholder')}
                          {...input}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='lastName'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.lastName.title')}</LabelInput>
                        <InputText
                          placeholder={t('profile.lastName.placeholder')}
                          {...input}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>
                <FormField
                  name='email'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.email.title')}</LabelInput>
                        <InputText
                          placeholder={t('profile.email.placeholder')}
                          {...input}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='password'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.password.title')}</LabelInput>
                        <InputPassword
                          placeholder={t('profile.password.placeholder')}
                          {...input}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='confirmPassword'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>
                          {t('profile.confirmPassword.title')}
                        </LabelInput>
                        <InputPassword
                          placeholder={t('profile.confirmPassword.placeholder')}
                          {...input}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='country'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.country.title')}</LabelInput>
                        <CountryDropdownStyled
                          {...input}
                          defaultOptionLabel={t('profile.country.placeholder')}
                          priorityOptions={['FR']}
                          onBlur={undefined}
                        />
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='languages'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <InputContainer>
                        <LabelInput>{t('profile.languages.title')}</LabelInput>
                        <Select
                          mode='multiple'
                          allowClear
                          placeholder={t('profile.languages.placeholder')}
                          {...input}
                        >
                          {Object.values(AvailableLanguageAssets).map(
                            (value: { langCode: ILanguage }) => (
                              <Select.Option
                                key={value.langCode}
                                value={value.langCode}
                              >
                                {t(`language.${value.langCode}`)}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </InputContainer>
                    );
                  }}
                </FormField>

                <FormField
                  name='emailOptIn'
                  matchHasError={({ meta }) =>
                    meta.touched === true && meta.error !== undefined
                  }
                >
                  {({ input }) => {
                    return (
                      <SwitchContainer>
                        <LabelInput>{t('profile.emailOptIn.title')}</LabelInput>
                        <SwitchInputContainer>
                          <SwitchOptionLabel>
                            {t('profile.emailOptIn.true')}
                          </SwitchOptionLabel>
                          <Switch {...input} checked={values.emailOptIn} />
                          <SwitchOptionLabel>
                            {t('profile.emailOptIn.false')}
                          </SwitchOptionLabel>
                        </SwitchInputContainer>
                      </SwitchContainer>
                    );
                  }}
                </FormField>
                <ButtonStyled
                  htmlType='submit'
                  type='primary'
                  disabled={
                    hasValidationErrors ||
                    (hasSubmitErrors && !dirtySinceLastSubmit) ||
                    submitting
                  }
                >
                  {t('profile.button')}
                </ButtonStyled>
                <ErrorMessage display={hasSubmitErrors}>
                  {t('profile.errors.error')}
                </ErrorMessage>
              </FormContainer>
            );
          }}
        </Form>
      </SettingsLayout>
    </Layout>
  );
}

const Title = styled(Typography)`
  margin: 20px;
  font-size: 40px;
  font-weight: bold;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  padding: 0px 50px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin: 35px 0;
  background-color: ${colors.buttonBackground};
  :hover {
    background-color: ${colors.darkPink};
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const SwitchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  @media ${MEDIA_QUERIES.MOBILE} {
    justify-content: unset;
    margin-left: -7px;
    margin-top: 10px;
  }
`;

const SwitchOptionLabel = styled.span`
  padding: 0 7px;
`;

const CountryDropdownStyled = styled(CountryDropdown)`
  padding: 12px 10px;
  border: 1px solid ${colors.greyLight};

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;
