import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

export const LabelInput: React.FC<Props> = ({ children }: Props) => {
  return (
    <Main>
      <TypographyLabelStyled>{children}</TypographyLabelStyled>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
`;

const TypographyLabelStyled = styled(Typography)`
  font-size: 1.1em;
  font-weight: bold;
  margin: 10 0;
`;
