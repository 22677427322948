import { default as React, ReactNode } from 'react';
import styled from 'styled-components';
import { Footer } from './Footer';
import { Header } from './Header/Header';

interface Props {
  children: ReactNode;
}

export function Layout(props: Props): JSX.Element {
  const { children } = props;

  return (
    <Main>
      <Header />
      {children}
      <Footer />
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
