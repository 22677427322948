import { Image, Typography } from 'antd';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import styled from 'styled-components';
import { colors, IMAGE_FALLBACK, MEDIA_QUERIES } from '../../themes';
import { ApiStory } from '../../types';

interface Props {
  title: string;
  text: string;
  stories: ApiStory[];
  currentlySelected: string;
  onChoice: (value: ApiStory) => void;
  next: () => void;
}

export function StoryInteractiveSelectionChoicePage(props: Props): JSX.Element {
  const { title, text, stories, currentlySelected, onChoice, next } = props;

  const isMobile = useMediaPredicate(MEDIA_QUERIES.MOBILE);

  function choiceSelection(selection: ApiStory) {
    onChoice(selection);
    next();
  }

  return (
    <Main>
      <Title>{title}</Title>
      {text !== '' && <Text>{text}</Text>}
      <ListItem>
        {stories.map((story: ApiStory) => {
          return (
            <Item key={story.id}>
              <ImageStyled
                onClick={() => choiceSelection(story)}
                isselected={story.id === currentlySelected}
                height={isMobile ? 75 : 150}
                width={isMobile ? 75 : 150}
                preview={false}
                src={story.asset && story.asset !== '' ? story.asset : 'error'}
                fallback={IMAGE_FALLBACK}
              />
              <ItemLabel>{story.name}</ItemLabel>
            </Item>
          );
        })}
      </ListItem>
    </Main>
  );
}

interface AvatarStyledProps {
  isselected: boolean;
  onClick: () => void;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 33%;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`;

const ImageStyled = styled(Image)`
  border: ${(props: AvatarStyledProps) =>
    props.isselected ? `5px solid ${colors.secondary}` : ''};

  border-radius: 50%;
  :hover {
    border: 5px solid ${colors.secondary};
    cursor: pointer;
  }
`;

const Title = styled(Typography)`
  font-size: 1.8em;
  font-weight: bold;
  color: ${colors.textPrimary};
  align-self: center;
`;

const Text = styled(Typography)`
  font-size: 1.2em;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${colors.textPrimary};
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;

const ItemLabel = styled(Typography)`
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 20px;
`;
