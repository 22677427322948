import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

interface Props extends React.ComponentProps<typeof Input> {}

export const InputPassword: React.FC<Props> = (props) => {
  return <InputStyled {...props} />;
};

const InputStyled = styled(Input.Password)`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${colors.greyLight};
  border-radius: 2px;
  background-color: white;
  padding: 0 15px;
  span {
    margin: 0 8px;
  }
  input {
    min-height: 45px;
    width: 100%;
    border: 0;
  }
`;
