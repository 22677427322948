import React, { useState } from 'react';
import { generateEmptyStoryConfig } from '../services/linearStory';
import { StoryCreationConfig } from '../types';

interface State {
  storyConfig: StoryCreationConfig;
}

interface Context extends State {
  updateStoryConfig: (config: StoryCreationConfig) => void;
  storyConfig: StoryCreationConfig;
}

const defaultState: State = {
  storyConfig: generateEmptyStoryConfig(),
};

const StoryCreationContext = React.createContext<Context>({
  ...defaultState,
  updateStoryConfig: () => {
    throw new Error('StoryCreationContext.updateStoryConfig has not been set');
  },
});

function useStoryCreationProvider() {
  const [storyConfig, setStoryConfig] = useState<StoryCreationConfig>(
    generateEmptyStoryConfig()
  );

  function updateStoryConfig(config: StoryCreationConfig) {
    setStoryConfig(config);
  }

  return {
    storyConfig,
    updateStoryConfig,
  };
}

interface Props {
  children: React.ReactNode;
}

export const StoryCreationProvider = ({ children }: Props): JSX.Element => {
  const context: Context = useStoryCreationProvider();
  return (
    <StoryCreationContext.Provider value={context}>
      {children}
    </StoryCreationContext.Provider>
  );
};

export const useStoryCreationContext = (): Context =>
  React.useContext(StoryCreationContext);
