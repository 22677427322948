import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatHTMLText } from '../../services/utils';
import { Layout } from '../Layout';

export function LegalNoticePage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Layout>
      <Main>
        <StyledTypography>
          {formatHTMLText(t('legalNotice.content'))}
        </StyledTypography>
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 10%;
`;

const StyledTypography = styled(Typography)`
  text-align: justify;
`;
