import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatHTMLText } from '../../services/utils';
import { MEDIA_QUERIES } from '../../themes';
import { Layout } from '../Layout';
import { getFAQBlocksFromi18nObject } from './FAQPage.utils';

const { Panel } = Collapse;

interface FAQPageProps {}

export interface FAQBlock {
  question: string;
  answer: string;
}

export function FAQPage(props: FAQPageProps): JSX.Element {
  const {} = props;

  const { t } = useTranslation();

  const i18nObject = t('FAQ.blocks');

  const faqBlocks = getFAQBlocksFromi18nObject(i18nObject);

  return (
    <Layout>
      <Main>
        <CollapseStyled accordion>
          {faqBlocks.map((block: FAQBlock, index: number) => (
            <PanelStyled key={index} header={block.question}>
              {formatHTMLText(block.answer)}
            </PanelStyled>
          ))}
        </CollapseStyled>
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  padding: 30px 25%;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 30px 10%;
  }
`;

const CollapseStyled = styled(Collapse)`
  /* .ant-collapse { */
  border-radius: 20px;
  /* } */
  .ant-collapse-item:last-child {
    border-radius: 0 0 20px 20px !important;
  }
  .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
  }
`;

const PanelStyled = styled(Panel)``;
