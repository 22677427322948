import { Avatar, Dropdown, Image, Menu, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLanguageContext } from '../../../contexts/LanguageContext';
import { AvailableLanguageAssets, ILanguage } from '../../../i18n/init';
import { convertEnumToArray } from '../../../services/utils';
import { IMAGE_FALLBACK } from '../../../themes';

interface Props {}

type AvailableLanguages = keyof typeof AvailableLanguageAssets;

export function LanguageSelector(props: Props): JSX.Element {
  const {} = props;

  const { changeLanguage, currentLanguage } = useLanguageContext();
  const { t } = useTranslation();

  const dropdown = (
    <Menu>
      {convertEnumToArray(ILanguage).map((lang: string) => (
        <Menu.Item key={lang}>
          <FlagContainer
            onClick={() =>
              changeLanguage(
                AvailableLanguageAssets[lang as AvailableLanguages].langCode
              )
            }
          >
            <Avatar
              src={
                <Image
                  preview={false}
                  src={
                    AvailableLanguageAssets[lang as AvailableLanguages].asset
                  }
                  fallback={IMAGE_FALLBACK}
                />
              }
            />
            <FlagTypography>{t(`language.${lang}`)}</FlagTypography>
          </FlagContainer>
        </Menu.Item>
      ))}
    </Menu>
  );

  const currentLanguageAsset = AvailableLanguageAssets[currentLanguage]?.asset;

  return (
    <DropdownStyled
      overlay={dropdown}
      placement='bottomRight'
      arrow
      trigger={['click']}
    >
      <SelectorTrigger
        src={
          <Image
            preview={false}
            src={currentLanguageAsset || 'error'}
            fallback={IMAGE_FALLBACK}
          />
        }
      />
    </DropdownStyled>
  );
}

const SelectorTrigger = styled(Avatar)`
  cursor: pointer;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownStyled = styled(Dropdown)`
  margin-right: 35px;
`;

const FlagTypography = styled(Typography)`
  margin-left: 10px;
`;
