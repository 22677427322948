/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApiChoice, User } from '../../types';
import { ApiPrice } from './../../types/api/ApiPrice';
import { ApiStory } from './../../types/api/ApiStory';
import { http } from './httpService';

const register = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<string> => {
  const response = await http.post('/auth/register', {
    email,
    password,
    firstName,
    lastName,
  });
  return response.data.access_token;
};

const login = async (email: string, password: string): Promise<string> => {
  const response = await http.post('/auth/login', {
    email,
    password,
  });
  return response.data.access_token;
};

const me = async (): Promise<User> => {
  const response = await http.get('/auth/me');
  return response.data as User;
};

const forgetPassword = async (email: string): Promise<User> => {
  const response = await http.post('/auth/forget-password', { email });
  return response.data as User;
};

const resetPasswordVerifyToken = async (
  resetToken: string
): Promise<string> => {
  const response = await http.get(`/auth/reset-password/verify/${resetToken}`);
  return response;
};

const resetPassword = async (
  resetPasswordToken: string,
  password: string
): Promise<string> => {
  const response = await http.post('/auth/reset-password', {
    resetPasswordToken,
    password,
  });
  return response.data.access_token;
};

const getAllStories = async (): Promise<ApiStory[]> => {
  const response = await http.get('/stories');
  return response.data as ApiStory[];
};

const getChoice = async (choiceId: string): Promise<ApiChoice> => {
  const response = await http.get(`/choices/${choiceId}`);
  return response.data as ApiChoice;
};

const getStripePrices = async (): Promise<ApiPrice[]> => {
  const response = await http.get('/stripe/plans');
  return response.data;
};

const purchase = async (
  priceId: string,
  stripeCardToken: any,
  coupon: string
): Promise<any> => {
  const response = await http.post('/stripe/purchase', {
    priceId,
    stripeCardToken,
    coupon,
  });
  return response.data;
};

interface PatchProfileBody extends User {
  password: string;
}

const patchProfile = async (user: Partial<PatchProfileBody>): Promise<any> => {
  const response = await http.patch('/user/profile', user);
  return response.data;
};

const contact = async (
  email: string,
  fullname: string,
  message: string
): Promise<any> => {
  const response = await http.post('/contact', { email, fullname, message });
  return response.data;
};

const cancelSubscription = async () => {
  const response = await http.post('/stripe/cancel');
  return response.data;
};

const checkCoupon = async (coupon: string): Promise<boolean> => {
  const response = await http.get(
    `/stripe/coupon/${coupon.length > 0 ? coupon : 'none'}`
  );
  return response.data;
};

const calculatePrice = async (priceId: string, coupon: string) => {
  const response = await http.post('/stripe/calculatePrice', {
    stripePriceId: priceId,
    coupon,
  });
  return response.data;
};

export default {
  getAllStories,
  getChoice,
  register,
  login,
  me,
  forgetPassword,
  resetPasswordVerifyToken,
  resetPassword,
  getStripePrices,
  purchase,
  patchProfile,
  contact,
  cancelSubscription,
  checkCoupon,
  calculatePrice,
};
