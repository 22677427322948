import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  email: string;
  firstName: string;
  lastName: string;
  message: string;
}

export interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  message: string;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const { email = '', firstName = '', lastName = '', message = '' } = values;
  return {
    email,
    message,
    firstName,
    lastName,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    email: values.email,
    message: values.message,
    firstName: values.firstName,
    lastName: values.lastName,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};

  if (!Validator.isEmail(values.email))
    errors.email = 'Veuillez saisir un email valide';
  if (Validator.isEmpty(values.lastName))
    errors.password = 'Veuillez renseigner votre nom';
  if (Validator.isEmpty(values.firstName))
    errors.password = 'Veuillez renseigner votre prénom';
  if (Validator.isEmpty(values.message))
    errors.password = 'Veuillez écrire un message';

  return errors;
}
