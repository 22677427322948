import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { CookiesBar, ErrorFallback } from './components';
import StateProviders from './contexts/StateProviders';
import AppRouter from './router/Router';

function App(): JSX.Element {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <StateProviders>
        <AppRouter />
        <CookiesBar />
        <ToastContainer position='bottom-left' autoClose={5000} closeOnClick />
      </StateProviders>
    </ErrorBoundary>
  );
}

export default App;
