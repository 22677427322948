import { Image } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FormApi, FORM_ERROR } from 'final-form';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, FormField, InputText, LabelInput } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocalNotification } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import ApiService from '../../services/network/apiService';
import { colors, MEDIA_QUERIES } from '../../themes';
import { Layout } from '../Layout';
import {
  FormValues,
  getFormInitialValues,
  getFormStatePayload,
  validateFormValues,
} from './ContactPage.formstate';

interface ContactPageProps {}

export function ContactPage(props: ContactPageProps): JSX.Element {
  const {} = props;

  const { t } = useTranslation();
  const { notify } = useLocalNotification();
  const { currentUser } = useAuthContext();

  async function onSubmit(
    _: unknown,
    formApi: FormApi<FormValues, FormValues>
  ): Promise<{ [FORM_ERROR]: string | React.ReactNode } | undefined> {
    const formState = formApi.getState();
    const payload = getFormStatePayload(formState);

    try {
      const { email, firstName, lastName, message } = payload;
      await ApiService.contact(email, `${firstName} ${lastName}`, message);
      notify(t('contact.success'), 'success');
      history.push(ROUTES.HOME);
    } catch (e) {
      return { [FORM_ERROR]: 'Contact Failed' };
    }
  }

  return (
    <Layout>
      <Main>
        <Title>{t('contact.title')}</Title>
        <RowMode>
          <FormContainer>
            <InfoText>{t('contact.text.formDescription')}</InfoText>
            <Form
              initialValues={getFormInitialValues({ ...currentUser })}
              validate={validateFormValues}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                hasValidationErrors,
                hasSubmitErrors,
                dirtySinceLastSubmit,
                submitting,
              }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
              any) => {
                return (
                  <FormLayoutContainer onSubmit={handleSubmit}>
                    <FormField
                      name='firstName'
                      matchHasError={({ meta }) =>
                        meta.touched === true && meta.error !== undefined
                      }
                    >
                      {({ input }) => {
                        return (
                          <InputContainer>
                            <LabelInput>
                              {t('contact.firstName.title')}
                            </LabelInput>
                            <InputText
                              placeholder={t('contact.firstName.placeholder')}
                              {...input}
                            />
                          </InputContainer>
                        );
                      }}
                    </FormField>
                    <FormField
                      name='lastName'
                      matchHasError={({ meta }) =>
                        meta.touched === true && meta.error !== undefined
                      }
                    >
                      {({ input }) => {
                        return (
                          <InputContainer>
                            <LabelInput>
                              {t('contact.lastName.title')}
                            </LabelInput>
                            <InputText
                              placeholder={t('contact.lastName.placeholder')}
                              {...input}
                            />
                          </InputContainer>
                        );
                      }}
                    </FormField>
                    <FormField
                      name='email'
                      matchHasError={({ meta }) =>
                        meta.touched === true && meta.error !== undefined
                      }
                    >
                      {({ input }) => {
                        return (
                          <InputContainer>
                            <LabelInput>{t('contact.email.title')}</LabelInput>
                            <InputText
                              placeholder={t('contact.email.placeholder')}
                              {...input}
                            />
                          </InputContainer>
                        );
                      }}
                    </FormField>
                    <FormField
                      name='message'
                      matchHasError={({ meta }) =>
                        meta.touched === true && meta.error !== undefined
                      }
                    >
                      {({ input }) => {
                        return (
                          <InputContainer>
                            <LabelInput>
                              {t('contact.message.title')}
                            </LabelInput>
                            <TextArea
                              placeholder={t('contact.message.placeholder')}
                              rows={5}
                              {...input}
                            />
                          </InputContainer>
                        );
                      }}
                    </FormField>

                    <ButtonStyled
                      htmlType='submit'
                      type='primary'
                      disabled={
                        hasValidationErrors ||
                        (hasSubmitErrors && !dirtySinceLastSubmit) ||
                        submitting
                      }
                    >
                      {t('contact.button')}
                    </ButtonStyled>
                  </FormLayoutContainer>
                );
              }}
            </Form>
          </FormContainer>
          <InfoContainer>
            <InfoText>{t('contact.text.info1')}</InfoText>
            <SocialMedia>
              <a
                href='https://www.facebook.com/KIDcreastory'
                rel='noreferrer'
                target='_blank'
              >
                <ImgStyled
                  width={40}
                  preview={false}
                  src='/assets/socialMedia/facebookLogo.png'
                  onClick={() => {
                    return;
                  }}
                />
              </a>
              <a
                href='https://www.instagram.com/kidcreastory/'
                rel='noreferrer'
                target='_blank'
              >
                <ImgStyled
                  width={40}
                  preview={false}
                  src='/assets/socialMedia/instagramLogo.png'
                  onClick={() => {
                    return;
                  }}
                />
              </a>
            </SocialMedia>
            <InfoText>
              <LinkStyled href='/faq'>{t('contact.text.info2')}</LinkStyled>
            </InfoText>
          </InfoContainer>
        </RowMode>
      </Main>
    </Layout>
  );
}

const Main = styled.div`
  font-size: 1.5em;
  padding: 30px 0;
  text-align: center;
  background: linear-gradient(
    135deg,
    rgba(253, 247, 192, 1) 0%,
    rgba(172, 252, 242, 1) 100%
  );
`;

const Title = styled.div`
  font-size: 1.5em;
  margin-bottom: 20px;
`;

const RowMode = styled.div`
  display: flex;
  flex-direction: row;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  padding: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  text-align: center;
  padding: 20px;

  @media ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

const InfoText = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const FormLayoutContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgStyled = styled(Image)`
  padding: 5px;
  :hover {
    cursor: pointer;
  }
`;

const ButtonStyled = styled(Button)`
  display: flex;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin: 35px 0;
  background-color: ${colors.buttonBackground};
  :hover {
    background-color: ${colors.darkPink};
  }
`;

const SocialMedia = styled.div``;

const LinkStyled = styled.a`
  text-decoration: none;
  text-decoration: underline;
  color: black;

  :hover {
    color: black;
  }
`;
