import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

interface CookiesBarProps {}

export function CookiesBar(props: CookiesBarProps): JSX.Element {
  const {} = props;

  const { t } = useTranslation();

  return (
    <CookieConsent overlay={false} buttonText={t('Cookies.acceptButton')}>
      {t('Cookies.message')}
    </CookieConsent>
  );
}
