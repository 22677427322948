/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FAQBlock } from '.';

export function getFAQBlocksFromi18nObject(i18nObject: any): FAQBlock[] {
  const faqBlocks: FAQBlock[] = [];
  Object.values(i18nObject).forEach((value: any) => {
    faqBlocks.push({ question: value.question, answer: value.answer });
  });
  return faqBlocks.filter(
    (block: FAQBlock) =>
      block.answer &&
      block.question &&
      typeof block.answer === 'string' &&
      typeof block.question === 'string'
  );
}
