export interface LinearStoryChoiceItem {
  value: string;
  title: string;
  asset: string;
  premium: boolean;
}

export enum LinearStoryResolveType {
  STORIES = 'STORIES',
  GENDER = 'GENDER',
  CHARACTER = 'CHARACTER',
  PLACE = 'PLACE',
  BASE = 'BASE',
  EMOTIONS = 'EMOTIONS',
  ITEMS = 'ITEMS',
}

export enum LinearStoryCreationPageStep {
  NAME = 'NAME',
  COLOR = 'COLOR',
  GENDER = 'GENDER',
  CHARACTER = 'CHARACTER',
  PLACE = 'PLACE',
  EMOTION = 'EMOTION',
  ITEM = 'ITEM',
  FINISHED = 'FINISHED',
}

export interface StoryCreationConfig {
  name: string;
  gender: string | '__EMPTY';
  color: string | '__EMPTY';
  character: string | '__EMPTY';
  place: string | '__EMPTY';
  emotion: string | '__EMPTY';
  item: string | '__EMPTY';
}
