import { i18n } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMountEffect } from '../hooks';
import { DEFAULT_LANGUAGE, ILanguage } from '../i18n/init';
import { http } from '../services/network/httpService';
import { convertEnumToArray } from '../services/utils';

interface State {
  currentLanguage: ILanguage;
}

interface Context extends State {
  changeLanguage: (language: ILanguage) => void;
  currentLanguage: ILanguage;
}

const defaultState: State = {
  currentLanguage: DEFAULT_LANGUAGE,
};

const LanguageContext = React.createContext<Context>({
  ...defaultState,
  changeLanguage: () => {
    throw new Error('LanguageContext.changeLanguage has not been set');
  },
});

function useLanguageProvider(i18n: i18n) {
  const currenti18nLanguage = i18n.language;

  const [currentLanguage, setCurrentLanguage] = useState<ILanguage>(
    DEFAULT_LANGUAGE
  );

  function selectDefaultLanguage(currenti18nLanguage: string): ILanguage {
    const availableLanguages = convertEnumToArray(ILanguage);
    const compatiblei18nLanguage = currenti18nLanguage.split('-')[0];
    if (availableLanguages.includes(compatiblei18nLanguage))
      return ILanguage[compatiblei18nLanguage as keyof typeof ILanguage];
    return DEFAULT_LANGUAGE;
  }

  function changeLanguage(language: ILanguage) {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
    localStorage.setItem('language', language);
    http.setLanguage(language);
  }

  useMountEffect(() => {
    const compatiblei18nLanguage = selectDefaultLanguage(currenti18nLanguage);
    changeLanguage(compatiblei18nLanguage);
  });

  return {
    currentLanguage,
    changeLanguage,
  };
}

interface Props {
  children: React.ReactNode;
}

export const LanguageProvider = ({ children }: Props): JSX.Element => {
  const { i18n } = useTranslation(undefined, { useSuspense: false });

  const context: Context = useLanguageProvider(i18n);
  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = (): Context =>
  React.useContext(LanguageContext);
