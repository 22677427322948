import { FormState } from 'final-form';
import Validator from 'validator';

export interface FormPayload {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  confirmPassword: string;
  country: string;
  languages: string[];
  emailOptIn: boolean;
}

export interface FormValues {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  confirmPassword: string;
  country: string;
  languages: string[];
  emailOptIn: boolean;
}

export function getFormInitialValues(
  values: RecursivePartial<FormValues> = {}
): FormValues {
  const {
    lastName = '',
    firstName = '',
    email = '',
    password = '',
    confirmPassword = '',
    country = '',
    languages = [],
    emailOptIn = false,
  } = values;
  return {
    lastName,
    firstName,
    email,
    password,
    confirmPassword,
    country,
    languages,
    emailOptIn,
  };
}

export function getFormStatePayload(
  formState: FormState<FormValues>
): FormPayload {
  const { values } = formState;
  const payload = {
    lastName: values.lastName,
    firstName: values.firstName,
    email: values.email,
    password: values.password,
    confirmPassword: values.confirmPassword,
    country: values.country,
    languages: values.languages,
    emailOptIn: values.emailOptIn,
  };
  return payload;
}

export function validateFormValues(
  values: FormValues
): ErrorFormValues<FormValues> {
  const errors: ErrorFormValues<FormValues> = {};
  if (!Validator.isEmail(values.email))
    errors.email = 'Veuillez saisir un email valide';
  if (Validator.isEmpty(values.password))
    errors.password = 'Veuillez introduire votre mot de passe';
  if (values.password !== values.confirmPassword)
    errors.confirmPassword = 'Les mots de passes sont différents';
  if (Validator.isEmpty(values.firstName))
    errors.password = 'Veuillez renseigner votre prénom';
  if (Validator.isEmpty(values.lastName))
    errors.password = 'Veuillez renseigner votre nom';

  return errors;
}
