import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StoryText, StoryTitle } from '.';
import { formatHTMLText } from '../../services/utils';
import { MEDIA_QUERIES } from '../../themes';

interface StoryDisplayProps {
  asset: string;
  title: string;
  text: string;
  renderActions: () => JSX.Element;
  disableInsideScroll?: boolean;
}

export function StoryDisplay(props: StoryDisplayProps): JSX.Element {
  const { asset, title, text, renderActions, disableInsideScroll } = props;

  const textContainer = useRef<HTMLDivElement>(null);
  const fullScreen = useRef<HTMLDivElement>(null);

  function setPageScroll() {
    window.scrollTo({
      left: 0,
      top: fullScreen.current?.offsetTop,
    });
  }

  useEffect(() => setPageScroll(), [title]);

  useEffect(() => {
    if (textContainer.current && !disableInsideScroll) {
      textContainer.current.scrollTop = textContainer.current.scrollHeight;
    }
    setPageScroll();
    // eslint-disable-next-line
  }, [text]);

  return (
    <FullScreen asset={asset} ref={fullScreen}>
      <TextContainer ref={textContainer}>
        <StoryTitle>{title}</StoryTitle>
        <StoryText>{formatHTMLText(text)}</StoryText>
      </TextContainer>
      <ActionsContainer>{renderActions()}</ActionsContainer>
    </FullScreen>
  );
}

interface FullScreenProps {
  asset: string;
}

const FullScreen = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  justify-content: center;
  align-items: center;
  background: url(${(props: FullScreenProps) => props.asset});
  background-size: cover;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  padding: 3% 10%;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 9;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px 15px;
  border-radius: 10px;
  width: 80%;
  overflow-y: scroll;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex: 8;
    width: 100%;
  }
`;

const ActionsContainer = styled.div`
  flex: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 20px 0px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${MEDIA_QUERIES.MOBILE} {
    flex: 2;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
  }
`;
