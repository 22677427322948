import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMountEffect } from '../../hooks';
import history from '../../router/history';
import { ROUTES } from '../../router/Router';
import apiService from '../../services/network/apiService';
import { ApiPrice } from '../../types/api/ApiPrice';
import { PaymentPageForm } from './PaymentPageForm';

interface Params {
  stripePriceId?: string;
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export function PaymentPage(): JSX.Element {
  const { stripePriceId } = useParams<Params>();
  const { isAuthenticated, currentUser } = useAuthContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [info, setInfo] = useState<ApiPrice | null>(null);
  const [isSubscriptionCheckDone, setIsSubscriptionCheckDone] = useState(false);

  if (!stripePriceId) {
    toast(t('payment.notify.error'));
    history.push(ROUTES.SUBSCRIPTION);
  }

  useMountEffect(() => {
    async function getPlanInfos() {
      const plans = await apiService.getStripePrices();
      const currentlySelectedPlan = plans.find(
        (plan) => plan.id === stripePriceId
      );
      if (!currentlySelectedPlan) {
        toast(t('payment.notify.error'));
        history.push(ROUTES.SUBSCRIPTION);
      } else {
        setInfo(currentlySelectedPlan);
      }
    }
    getPlanInfos();
  });

  useMountEffect(() => {
    if (!isAuthenticated) {
      toast(t('payment.notify.isNotAuthenticated'));
      history.push(`${ROUTES.LOGIN}?redirect=${pathname}`);
    }
  });

  useEffect(() => {
    console.log('PaymentPage', currentUser);
    if (isSubscriptionCheckDone) return;
    if (currentUser?.subscription?.isSubscribed) {
      toast(t('payment.notify.isAlreadySubscribed'));
      history.push(ROUTES.HOME);
    }
    if (currentUser) setIsSubscriptionCheckDone(true);
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Elements stripe={stripePromise}>
      {info && currentUser ? <PaymentPageForm stripePrice={info} /> : <Spin />}
    </Elements>
  );
}
