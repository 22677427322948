import { ReactElement } from 'react';
import ReactHtmlParser from 'react-html-parser';

export function convertEnumToArray<T>(enumToConvert: T): string[] {
  return Object.values(enumToConvert);
}

export function formatMultilineTextToHtml(text: string): string {
  const newText = text
    .split('\n')
    .map((str) => `${str}<br/>`)
    .join('');
  return newText;
}

export function formatHTMLText(text: string): ReactElement[] {
  return ReactHtmlParser(formatMultilineTextToHtml(text));
}
