import { useLocation } from 'react-router-dom';

type SearchQueryParams = { [key: string]: string | undefined };

export function parseSearchQuery(searchQuery: string): SearchQueryParams {
  if (searchQuery.length === 0) {
    return {};
  }
  const hashes = searchQuery.slice(searchQuery.indexOf('?') + 1).split('&');
  const params: SearchQueryParams = {};
  hashes.forEach((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}

export function useUrlSearchParams(): SearchQueryParams {
  const location = useLocation();
  return parseSearchQuery(location.search);
}
