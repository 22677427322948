import { ApiChoice } from './../types/api/ApiChoice';
import { ApiChoiceNext } from './../types/api/ApiChoiceNext';

export function displayInteractiveStory(storyPath: ApiChoice[]): string {
  return storyPath.map((choice: ApiChoice) => choice.text).join('\n\n');
}

export function isInteractiveStoryFinished(storyPath: ApiChoice[]): boolean {
  const lastChoice = getCurrentInteractiveChoice(storyPath);
  if (!lastChoice || lastChoice.isLastChoice) return true;
  return false;
}

export function getNextInteractiveChoices(
  storyPath: ApiChoice[]
): ApiChoiceNext[] {
  return getCurrentInteractiveChoice(storyPath)?.nextChoices || [];
}

export function getCurrentInteractiveChoice(
  storyPath: ApiChoice[]
): ApiChoice | null {
  const lastChoice = [...storyPath].pop();
  if (!lastChoice) return null; // TODO: handle this
  return lastChoice;
}
