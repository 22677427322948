import { Form as FormAntd } from 'antd';
import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props extends React.ComponentProps<typeof Field> {
  label?: string;
  isRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matchHasError?: (fieldRenderProps: FieldRenderProps<any>) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (fieldRenderProps: FieldRenderProps<any>) => React.ReactNode;
}

export const FormField: React.FC<Props> = (props: Props) => {
  const {
    label,
    isRequired,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matchHasError = (fieldRenderProps: FieldRenderProps<any>) => {
      const { meta } = fieldRenderProps;
      return meta.touched === true && meta.error !== undefined;
    },
    children,
    ...fieldProps
  } = props;

  const { t } = useTranslation();

  return (
    <Field
      // format and parse as identity to not transform `undefined` <=> ""
      format={(value) => value}
      parse={(value) => value}
      {...fieldProps}
    >
      {(fieldRenderProps) => {
        const hasError = matchHasError(fieldRenderProps);
        return (
          <ItemStyled
            label={label}
            labelCol={{ span: 24 }}
            validateStatus={hasError ? 'error' : undefined}
            help={hasError ? t(fieldRenderProps.meta.error) : undefined}
            required={isRequired}
          >
            {children(fieldRenderProps)}
          </ItemStyled>
        );
      }}
    </Field>
  );
};

const ItemStyled = styled(FormAntd.Item)`
  display: flex;
  flex: 1;
`;
