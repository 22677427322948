import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

interface Props extends React.ComponentProps<typeof Input.TextArea> {}

export const InputTextArea: React.FC<Props> = (props) => {
  return <TextAreaStyled {...props} />;
};

const TextAreaStyled = styled(Input.TextArea)`
  border: 1px solid ${colors.greyLight};
  border-radius: 2px;
  background-color: white;
  padding: 0 15px;
`;
